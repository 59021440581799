import * as React from 'react';
import platform from "../img/platform.png";
function Platform(){
    return(
        <div className="WhyContainer">
                <div className="PayoutSchedule"  data-aos="fade-up">

                    <div className="payoutWriting">
                    <dl>
                    <dt><b>Trading Environment</b></dt><br/>
                    <dd>We utilize MultiTrader as our trading platform, providing traders with a seamless trading experience. Additionally, our platform features a comprehensive dashboard where users can monitor their trading history metrics and easily request withdrawals.<br/><br/>
                    </dd>
                    </dl>
                    </div>

                    <div className="imgpayout">
                    <img src={platform} alt="#"/>
                    </div>
                </div>
                
        </div>
    )
}
export default Platform;
