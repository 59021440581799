import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Footer from '../../footer/Footer';
import Nav from '../../navbar/Nav';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
  
const AllPaymentByStripe = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      
      function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }
      
      const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
      ];

      

    const[State,setState]=useState([])
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const payment_intent = urlParams.get('payment_intent');
    useEffect(()=>{
        axios.get("https://www.multiverse-funding.com/api/get-all-payments")
        .then(e=>{
            // console.log(e.data)
             setState(e.data)
             e.data.map(event=>{
                // console.log(event.firstName)
                rows.push(createData(event.firstName, 159, 6.0, 24, 4.0))
             })
             
        })
        .catch(e=>{
            alert('something went wrong')
        })
    },[])

  return (
    <div>
        <Nav/>
        <div className='paid-by-stripe'>



        <TableContainer component={Paper}>
            <Table sx={{ maxWidth: 1000 }} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell align="right">Name</StyledTableCell>
                    <StyledTableCell align="right">Email</StyledTableCell>
                    <StyledTableCell align="right">account size</StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {State.map((row) => (
                    <StyledTableRow key={row.name}>
                    <StyledTableCell align="right">{row.id}</StyledTableCell>
                    <StyledTableCell align="right">{row.firstName} {row.LastName}</StyledTableCell>
                    <StyledTableCell align="right">{row.email}</StyledTableCell>
                    <StyledTableCell align="right">{row.sku}</StyledTableCell>
                    <StyledTableCell align="right">{row.processed===true?"paid":"not Paid"}</StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>











                            {/* <table
                                className="table star-student table-hover table-center table-borderless table-striped">
                                <thead className="thead-light">
                                    <tr>
                                        <th>ID</th>
                                        <th>Fullname</th>
                                        <th className="text-center">Email</th>
                                        <th className="text-center">Processed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {State.map(event=>
                                        <tr>
                                                <td className="text-nowrap">
                                                    <div>{event.id}</div>
                                                </td>
                                                <td className="text-nowrap">
                                                    <a href="profile.html">
                                                        {event.firstName} {event.LastName}
                                                    </a>
                                                </td>
                                                <td className="text-center">{event.email}</td>
                                                <td className="text-center">{event.processed===true?"Yes":"No"}</td>
                                                <td className="text-end">
                                                <td className="text-end">
                                                    <IconButton aria-label="delete">
                                                        <EditIcon />
                                                    </IconButton>

                                                    <IconButton  aria-label="delete">
                                                        <DeleteIcon style={{color:"rgba(206, 15, 15, 0.585)"}}/>
                                                    </IconButton>
                                                </td>
                                                </td>
                                            </tr>
                                    
                                                
                                    )}
                                    </tbody>
                                </table> */}
        </div>
        <Footer/>
    </div>
    
  );
};


export default AllPaymentByStripe;
