import line from "../img/line.png";
import logo from "../img/logo.png";
import pilot from "../img/trustpilot.png";
import popup from "../img/discount.png";
import React from "react";
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import { SocialIcon } from 'react-social-icons'
import axios from 'axios'
import 'react-social-icons/discord'
import { CarouselProvider, DotGroup, Slider, Slide } from 'pure-react-carousel';
import EmailIcon from '@mui/icons-material/Email';
import RestoreIcon from '@mui/icons-material/Restore';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { TextField } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Localbase from 'localbase'
import Nav from "../navbar/Nav";
import Footer from "../footer/Footer";
import { useDispatch } from 'react-redux';
import { addsize, addprice, addstep, addPlatform } from '../redux/counterSlice';
import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'
import StarfieldAnimation from "react-starfield-animation";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PayoutSchedules from './PayoutSchedules'
import Community from './Community'
import WhyUs from './WhyUs'
import Affiliate from './Affiliate'
import Platform from './Platform'
AOS.init();

function Home(props) {

  //affliate link
  const affliateUrl = "https://www.multiverse-funding.com/affiliate"
  //redux
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //alert for successfull message sent to support
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  //url for mailchimp email
  const url = "https://multiverse-funding.us9.list-manage.com/subscribe/post?u=87633bc5be15cc03d0aa0bb19&amp;id=0449c4f8be&amp;f_id=001e1be1f0"
  const SimpleForm = () => <MailchimpSubscribe url={url} />
  //local storage
  let db = new Localbase('multiversefundingdb')
  //handlers
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = useState(false)
  const [openerror, setOpenerror] = React.useState(false);
  const [checker, setchecker] = useState(false)
  const handleClick = () => {
    setOpen(true);
  };
  const handleClickerror = () => {
    setOpenerror(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleCloseerror = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenerror(false);
  };
  const [openemail, setOpenemail] = React.useState(false);

  const handleClickOpenemail = () => {
    setOpenemail(true);
  };

  const handleCloseemail = () => {
    setOpenemail(false);
  };



  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [comment, setComment] = useState("")
  const [realcomment, setReaComment] = useState("")



  const [choice, setChoice] = useState("2STEP")
  const [accchoice, setAccChoice] = useState(50000)
  const [price, setPrice] = useState()
  useEffect(() => {
    if (choice === "2STEP") {
      if (accchoice === 5000) {
        setPrice(45)
      }
      else if (accchoice === 10000) {
        setPrice(95)
      } else if (accchoice === 25000) {
        setPrice(195)
      } else if (accchoice === 50000) {
        setPrice(295)
      } else if (accchoice === 100000) {
        setPrice(495)
      } else if (accchoice === 200000) {
        setPrice(895)
      }
    } else {
      if (accchoice === 5000) {
        setPrice(95)
      } else if (accchoice === 10000) {
        setPrice(195)
      } else if (accchoice === 25000) {
        setPrice(295)
      } else if (accchoice === 50000) {
        setPrice(395)
      } else if (accchoice === 100000) {
        setPrice(595)
      } else if (accchoice === 200000) {
        setPrice(995)
      }
    }
  }, [accchoice])

  useEffect(() => {
    handleClickOpenemail()
  }, [])

  const [platform, setPlatform] = React.useState("");

  const handleChange = (event) => {
    setPlatform(event.target.value);
  };
  //turn 10000 to 10k
  function abbreviateNumber(number) {
    if (number >= 1000) {
      return (number / 1000).toFixed(0) + 'k';
    }
    return number.toString();
  }
  return (
    <div className="App">
      <Nav />
      <StarfieldAnimation className="Starsanime"

      />
      <div className="container">

        <div className="below-nav">
          <div className="below-left" >
            <h1>Discover Evaluation Plans up to <b>$600,000</b></h1>

            <p>Take on the <b><span style={{ color: "#fff" }}>Challenge</span></b></p>
            <p>Pass the <b>Challenge</b></p>
            <p>and become a  <b><span>Master Trader</span></b></p>
            <a href="#challenges">
              <Button variant="contained" className="btn-eva">Evaluation Plan</Button>
            </a>

          </div>
          <div className="below-right">
            <h2>Start trading with Virtual Money</h2>
            <p>Complete any Evaluation Program to increase your buying power</p>
            <a target="_blank" rel="noreferrer" href="https://discord.gg/5kF3qczuaf"><Button variant="contained" className="btn-discord"><SocialIcon style={{ margin: "0px 10px" }} url="https://discord.gg/5kF3qczuaf" /> Join discord community</Button></a>
            {/* <a target="_blank" rel="noreferrer" href={affliateUrl}><Button   variant="contained" className="btn-discord"> Affliate Portal</Button></a> */}
          </div>
        </div>
        <div id="challenges"></div>
        <div className="stepschallenge">

          <Button className={choice === "2STEP" ? "" : "unselected"} onClick={() => {
            if (choice !== "2STEP") {
              setChoice("2STEP")
              setAccChoice(100000)
              setPrice(495);
              setPlatform("MultiTrader")
            }
          }} variant='contained'>2 step evaluation</Button>

          <Button className={choice === "INSTANT FUNDING" ? "" : "unselected"} onClick={() => {
            if (choice !== "INSTANT FUNDING") {
              setChoice("INSTANT FUNDING")
              setAccChoice(50000)
              setPrice(395);
              setPlatform("MultiTrader")
            }
          }} variant='contained'>INSTANT FUNDING (HFT series)</Button>

        </div>




        <div className="stepschallengeacc">

          <Button className={accchoice !== 5000 ? "unselected" : ""} onClick={() => {
            if (accchoice !== 5000) {
              setAccChoice(5000)
            }
          }} variant='contained'>$5,000</Button>

          <Button className={accchoice !== 10000 ? "unselected" : ""} onClick={() => {
            if (accchoice !== 10000) {
              setAccChoice(10000)
            }
          }} variant='contained'>$10,000</Button>

          <Button className={accchoice !== 25000 ? "unselected" : ""} onClick={() => {
            if (accchoice !== 25000) {
              setAccChoice(25000)
            }
          }} variant='contained'>$25,000</Button>
          <Button className={accchoice !== 50000 ? "unselected" : ""} onClick={() => {
            if (accchoice !== 50000) {
              setAccChoice(50000)
            }
          }} variant='contained'>$50,000</Button>

          <Button className={accchoice !== 100000 ? "unselected" : ""} onClick={() => {
            if (accchoice !== 100000) {
              setAccChoice(100000)
            }
          }} variant='contained'>$100,000</Button>

          <Button className={accchoice !== 200000 ? "unselected" : ""} onClick={() => {
            if (accchoice !== 200000) {
              setAccChoice(200000)
            }
          }} variant='contained'>$200,000</Button>


        </div>

        <div className="platform">
          <FormControl style={{ color: "#fff" }}>
            <FormLabel id="demo-controlled-radio-buttons-group" style={{ color: "#fff" }}>Platform</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={platform}
              onChange={handleChange}
            >
              <FormControlLabel value="MultiTrader" control={<Radio />} label="MultiTrader" />
              {/* {choice==="INSTANT FUNDING"?"":<FormControlLabel value="MT5" control={<Radio />} label="MT5"/>} */}

            </RadioGroup>
          </FormControl>
        </div>


        <div className="checkout">
          <div className="checkout1">
            <h2>Fast access</h2>
            <p>Once the payment has been received you will instantly get access to your trading credentials. </p>
          </div>
          <div>
            <div className="checkout2">
              <Button variant="contained" className="btn-e">${price}</Button>
              <Button variant="contained" className="btn-ev" onClick={() => {
                if (platform !== "") {
                  dispatch(addsize(Number(accchoice) || 0))
                  dispatch(addprice(Number(price) || 0))
                  dispatch(addPlatform(platform || 0))
                  if (choice === "2STEP") {
                    dispatch(addstep("2STEP" || 0))
                  } else {
                    dispatch(addstep("INSTANT FUNDING" || 0))
                  }
                  navigate("/checkout")
                }

              }}>Checkout</Button>
            </div>
          </div>


        </div>

        {/* price and details phases */}

        <div className="phases-details">
          <div style={{ border: " 0px solid #fff" }}>
            <h5>-</h5>
            {/* <h5>Phase 1</h5> */}
            {choice === "2STEP" ? <h5>Phase 1</h5> : ""}
            {choice === "2STEP" ? <h5>Phase 2</h5> : ""}
            <h5>Master Trader</h5>
          </div>
          <div>
            <p>Trading Period</p>
            {/* <p><img width={25} height={25} src={logo} alt="multiverse funding logo"/></p> */}
            {choice === "2STEP" ? <p><img width={25} height={25} src={logo} alt="multiverse funding logo" /></p> :""}
            {choice === "2STEP" ? <p><img width={25} height={25} src={logo} alt="multiverse funding logo" /></p> : ""}
            <p><img width={25} height={25} src={logo} alt="multiverse funding logo" /></p>
          </div>
          <div>
            <p>Minimum Trading Day</p>
            {choice === "2STEP" ? <p><img width={25} height={25} src={logo} alt="multiverse funding logo" /></p> : ""}
            {choice === "2STEP" ? <p><img width={25} height={25} src={logo} alt="multiverse funding logo" /></p> : ""}
            {choice === "2STEP" ? <p>3 days</p> : <p>10 days</p>}
          </div>
          <div>
            <p>Max Daily Loss</p>
            {choice === "2STEP" ? <p>5% (${abbreviateNumber((accchoice * 5) / 100)})</p> : ""}
            {choice === "2STEP" ? <p>5% (${abbreviateNumber((accchoice * 5) / 100)})</p> : ""}
            {choice === "2STEP" ? <p>5% (${abbreviateNumber((accchoice * 5) / 100)})</p> : <p>4% (${abbreviateNumber((accchoice * 4) / 100)})</p>}
          </div>
          <div>
            <p>Max Overall Loss</p>
            {choice === "2STEP" ? <p>10% (${abbreviateNumber((accchoice * 10) / 100)})</p> : ""}
            {choice === "2STEP" ? <p>10% (${abbreviateNumber((accchoice * 10) / 100)})</p> : ""}
            {choice === "2STEP" ? <p>10% (${abbreviateNumber((accchoice * 10) / 100)})</p> : <p>8% (${abbreviateNumber((accchoice * 8) / 100)})</p>}

          </div>
          <div>
            <p>Profit Target</p>
            {choice === "2STEP" ? <p>8% (${abbreviateNumber((accchoice * 8) / 100)})</p> :""}
            {choice === "2STEP" ? <p>5% (${abbreviateNumber((accchoice * 5) / 100)})</p> : ""}
            <p><img width={25} height={25} src={logo} alt="multiverse funding logo" /></p>
          </div>
          <div>
            <p>Profit Split</p>
            {choice === "2STEP" ? <p><img width={25} height={25} src={logo} alt="multiverse funding logo" /></p> : ""}
            {choice === "2STEP" ? <p><img width={25} height={25} src={logo} alt="multiverse funding logo" /></p> : ""}
            {choice === "2STEP" ? <p>up to 100%</p> : <p>up to 100%</p>}
          </div>
          <div>
            <p>Fee</p>
            {choice === "2STEP" ? <p style={{ width: "50%" }}>${price}</p> : <p style={{ width: "25%" }}>${price}</p>}
            {choice === "2STEP" ? <p>Non Refundable</p> : ""}
          </div>
        </div>



        {/* stages */}
        <div className="stagescontainer">
          <div className="stages" data-aos="fade-up">
            <h2>Stage Process to become a Master Trader</h2>
            <div className="roadmap">
              <div className="roadmap-left">

                <h3>Evaluation Stage</h3>
                <img src={line} alt="#" />
                <nav>
                  <div>
                    <h2>01.</h2>
                    <h4>Multiverse Funding Challenge</h4>
                    <p>A Multiverse Funding Challenge will be the first and only stage before earning real money. In this stage you will show your trading skills to become a Master Trader. </p>
                  </div>
                  <div>
                    <h3>02.</h3>
                    <h4>KYC</h4>
                    <p>A Multiverse Once the profit target is reached and no rules have been breached we move on to the KYC. In this stage you will have to provide your personal details. </p>
                  </div>
                </nav>

              </div>

              <div className="roadmap-right">
                <h3>Earn Real Money</h3>
                <div>
                  <h2>03.</h2>
                  <h4>Master Trader</h4>
                  <p>A The final and last stage will be Master Trader. In this stage you will start earning money. The virtual profit will be payout out as real money.</p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <PayoutSchedules />

        <Community />
        <Platform />
        <WhyUs />
        <Affiliate />
        <div className="ReviewAndContactContainer">
          <div className="ReviewAndContact" data-aos="zoom-in">
            <div className="trustpilot">
              <h4>Contact us</h4>
              {/* <p>Provided by Trustpilot</p> */}
              <div className="trustpilotimg">
                {/* <a href="https://uk.trustpilot.com/review/multiverse-funding.org" target="_blank" rel="noreferrer">
                    <img src={pilot} alt="#" />
                      </a> */}

                <p>send us an email to <a style={{ color: "#fff" }} href="mailto:support@multiverse-funding.com">support@multiverse-funding.com</a></p><br />
                <p>Live chat 24/5 7am-8pm GMT</p>
                <div>
                  <p><EmailIcon sx={{ fontSize: 30 }} /></p>
                  <a href="mailto:support@multiverse-funding.com" style={{ color: "#fff" }}>support@multiverse-funding.com</a>
                </div>
                <div>
                  <p><RestoreIcon sx={{ fontSize: 30 }} /></p>
                  <a href='/support' style={{ color: "#fff", textDecoration: "none" }}>Support available 24/5 (Live Chat 6AM-6PM GMT)</a>
                </div>

              </div>
              <div>
                <div></div>
              </div>
              {/* <div>
                <CarouselProvider
                
              naturalSlideWidth={1}
              naturalSlideHeight={1}
              totalSlides={4}
              infinite={true}
              isPlaying={true}
            >
              <Slider>
                <Slide index={0}><img src={review1} style={{height:"400px",width:"300px"}} alt="#" /></Slide>
                <Slide index={1}><img src={review2} style={{height:"400px",width:"300px"}}  alt="#"/></Slide>
                <Slide index={1}><img src={review3} style={{height:"300px",width:"300px"}} alt="#"/></Slide>
                <Slide index={1}><img src={review4} style={{height:"300px",width:"300px"}} alt="#"/></Slide>
              </Slider>
              <DotGroup
              showAsSelectedForCurrentSlideOnly={true}
              />
            </CarouselProvider>
                  </div> */}
            </div>


            <div className="contactus">
              <h3>Contact Us</h3>
              <div className="textfilling">
                <TextField
                  id="outlined-password-input"
                  label="Name"
                  type="text"
                  autoComplete="current-name"
                  className="inputEmail"
                  error={checker === true ? "error" : ""}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
                <br />
                <TextField
                  id="outlined-password-input"
                  label="Email"
                  type="email"
                  autoComplete="current-email"
                  className="inputEmail"
                  value={email}
                  error={checker === true ? "error" : ""}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
                <br />
                <TextField
                  error={checker === true ? "error" : ""}
                  id="outlined-multiline-static"
                  label="Comment"
                  multiline
                  rows={4}
                  className="inputEmail"
                  value={realcomment}
                  onChange={(e) => {
                    setReaComment(e.target.value)
                    setComment(`<p>${e.target.value}<br/><a href="mailto:${email}">reply</a></p>`)
                  }}
                /><br />
                <div style={{ display: "flex" }}>
                  <Button className="btn-nav" variant="contained" onClick={() => {
                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    if (name !== "" && email.match(validRegex) && comment !== "") {
                      setloading(true)
                      setchecker(false)
                      axios.post("https://www.multiverse-funding.com/api/sendmessage", { name, comment })
                        .then((response) => {
                          const sent = response.data.slice(0, 3);
                          console.log(sent)
                          console.log(response.status)
                          if (response.status === 200 && sent === "250") {
                            setName("")
                            setEmail("")
                            setComment("")
                            setReaComment("")
                            handleClick()
                            setloading(false)
                          }
                        })
                        .catch((error) => {
                          handleClickerror()
                          console.log(error);
                          setloading(false)
                        });
                    } else {
                      setchecker(true)
                    }
                  }}>  Submit</Button>{loading === true ? <CircularProgress style={{ display: "inline", marginLeft: "30px" }} color="success" /> : ""}
                </div>


              </div>

            </div>
          </div>

        </div>

        <Footer />


      </div>
      <Dialog

        open={openemail}
        keepMounted
        onClose={handleCloseemail}
        aria-describedby="alert-dialog-slide-description"

      >
        <div className="bgpopup">

          <DialogContent >
            <DialogContentText className="popup-contain" id="alert-dialog-slide-description">
              <img style={{ width: "300px" }} src={popup} alt="#" />
              <DialogTitle className="popup-title" style={{ fontSize: "15px" }}>30% Off until 30th September</DialogTitle>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="popup">
            <Button onClick={() => {

              handleCloseemail()
            }}>Continue</Button>
          </DialogActions>
        </div>

      </Dialog>

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          your message was sent successfully
        </Alert>
      </Snackbar>
      <Snackbar open={openerror} autoHideDuration={2000} onClose={handleCloseerror}>
        <Alert onClose={handleCloseerror} severity="error" sx={{ width: '100%' }}>
          something went wrong
        </Alert>
      </Snackbar>
    </div>);
}

export default Home;
