import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

function TradingDaysProgress({ activeDays, totalDays, MinimumTradingdays, CurrentMinimumTradingDays }) {
  let progress = (CurrentMinimumTradingDays / MinimumTradingdays) * 100;
  let progressOverall = (activeDays / totalDays) * 100;
  if (progress > 100) {
    progress = 100
  }
  if (progressOverall > 100) {
    progressOverall = 100
  }
  const textStyle = {
    fontFamily: 'Cabin, sans-serif',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    textAlign: 'center',
  };
  return (
    <Box style={{
      backgroundColor: "rgba(255, 255, 255, 1)",
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
      color: "white",
      padding: "25px",
      height: "fit-content",
      margin: "10px 10px"
    }} sx={{
      maxWidth: '1000px',
      minWidth: '200px'
    }}
    >
      <div>
        <div style={{
          display: "flex", alignItems: "center", justifyContent: "space-between", color: 'rgba(12, 11, 11, 1)', fontFamily: 'Cabin, sans-serif',
          fontSize: '20px',
          fontWeight: 500,
        }}>
          <Typography variant="subtitle2" gutterBottom>
            Active Trading Days
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
            {CurrentMinimumTradingDays} / {MinimumTradingdays}
          </Typography>
        </div>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: '10px',
            width: "100%",
            borderRadius: 5,
            backgroundColor: '#e0e0e0',
            '& .MuiLinearProgress-bar': {
              borderRadius: 5,
              backgroundColor: '#1a90ff',
            },
          }}
        />
      </div>


      <div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: 'rgba(12, 11, 11, 1)' }}>
          <Typography variant="subtitle2" gutterBottom>
            Overall Trading Days
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
            {totalDays} days
          </Typography>
        </div>
        <LinearProgress
          variant="determinate"
          value={progressOverall}
          sx={{
            height: '10px',
            borderRadius: 5,
            backgroundColor: '#e0e0e0',
            '& .MuiLinearProgress-bar': {
              borderRadius: 5,
              backgroundColor: '#4caf50',
            },
          }}
        />
      </div>

    </Box>
  );
}

export default TradingDaysProgress;
