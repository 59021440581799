import './App.css';
import {BrowserRouter,Route, Routes} from 'react-router-dom'
import React from 'react';
import Home from './homepage/Home';
import FAQ from './faq/FAQ';
import Support from './Support/Support';
import Checkout from './Checkout/Checkout';
import Affliate from './Affliate/Affliate';
import Terms from './faq/Terms';
import AdminAffiliate from './Affliate/AdminAffiliate';
import Lotsize from './Lotsize/Lotsize';
import CheckoutForm from './Checkout/Crypto/CheckoutForm';
import Successfull from "./successful/Successfull"
import AllPaymentByStripe from "./Checkout/Crypto/AllPaymentByStripe"
import Dashboard from './Dashboard/Dashboard';
import ResetPW from './Dashboard/ResetPW'
function App() {
    window.scrollTo(0, 0)
    
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<Home/>}/>
        <Route path='/faq' element={<FAQ/>}/>
        <Route path='/support' element={<Support/>}/>
        <Route path='/checkout' element={<Checkout/>}/>
        <Route path='/affiliate' element={<Affliate/>}/>
        <Route path='/terms-and-conditions' element={<Terms/>}/>
        <Route path='/AdminAffiliate' element={<AdminAffiliate/>}/>
        <Route path='/Lotsize' element={<Lotsize/>}/>
        <Route path='/checkout-with-card' element={<CheckoutForm/>}/>
        <Route path='/success' element={<Successfull/>}/>
        <Route path='/admin-check-all-payments-made-by-stripe' element={<AllPaymentByStripe/>}/>
        <Route path='/dashboard' element={<Dashboard/>}/>
        <Route path='/reset' element={<ResetPW/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;