import React, { useState, useEffect } from 'react';
import Footer from '../footer/Footer';
import Nav from '../navbar/Nav';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
export default function ResetPW() {
    const navigate = useNavigate();
    let url;
    if (process.env.NODE_ENV == 'production') {
        url = "https://www.multiverse-funding.com";
    } else {
        url = "http://localhost:8080";
        // url = "https://www.multiverse-funding.com";
    }
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };
    const [IsEmpty, setIsEmpty] = React.useState(false)
    const [otp, setOtp] = React.useState(null)
    const [email, setEmail] = React.useState("")
    const [showPassword, setShowPassword] = React.useState(false);
    const [password, setpassword] = React.useState("")
    const [confirm, setConfirm] = React.useState("")
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [opensnack, setopensnack] = useState(false);
    const [message, setMessage] = useState('');

    const handleClick = (customMessage) => {
        setMessage(customMessage);
        setopensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setopensnack(false);
    };
    return (
        <div>
            <Nav />
            <div class="back">
                <div class="back-content">
                    <h1>Reset password</h1>
                    <div className='login-field-affliate'>
                        <AccountCircle sx={{ color: IsEmpty ? "red" : "white", mr: 1, my: 0.5, width: "10%" }} />
                        <input value={email} onChange={(e) => { setEmail(e.target.value.toLowerCase().trim()) }} className='affliate-pw-login' placeholder="Email" variant="outlined" />
                        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', m: 1, width: "19%" }}>
                            <Button
                                sx={buttonSx}
                                disabled={loading} className='login-btn-affliate' variant="contained"
                                onClick={() => {
                                    setLoading(true)
                                    axios.post(`${url}/api/reset-pw-send-otp`, { email })
                                        .then(res => {
                                            handleClick("opt sent successfully, check your Email Inbox")
                                            setLoading(false)
                                        })
                                        .catch(err => {
                                            setLoading(false)
                                            console.log(err)
                                            alert("something went wrong, cant send otp")
                                        })
                                }}
                            >Send OTP</Button>
                        </Box>
                    </div>
                    <div className='login-field-affliate'>
                        <AccountCircle sx={{ color: IsEmpty ? "red" : "white", mr: 1, my: 0.5, width: "10%" }} />
                        <input value={otp} onChange={(e) => { setOtp(e.target.value.trim()) }} className='affliate-email-login' placeholder="OTP" variant="outlined" />
                    </div>
                    <div className='login-field-affliate'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ color: IsEmpty ? "red" : "white", mr: 1, my: 0.5, width: "10%" }}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        <input value={password} onChange={(e) => { setpassword(e.target.value) }} className='affliate-email-login' type={showPassword ? 'text' : 'password'} placeholder="Password" variant="outlined" />
                    </div>
                    <div className='login-field-affliate'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ color: IsEmpty ? "red" : "white", mr: 1, my: 0.5, width: "10%" }}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        <input value={confirm} onChange={(e) => { setConfirm(e.target.value) }} className='affliate-pw-login' type={showPassword ? 'text' : 'password'} placeholder="confirm password" variant="outlined" />
                        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', m: 1, width: "19%" }}>
                            <Button
                                sx={buttonSx}
                                disabled={loading} className='login-btn-affliate' variant="contained"
                                onClick={() => {
                                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                    if (email.match(validRegex) && password === confirm && otp !== null) {
                                        axios.post(`${url}/api/update-password`, { email, password, otp })
                                            .then(res => {
                                                handleClick(res.data.message)
                                                setEmail("")
                                                setConfirm("")
                                                setpassword("")
                                                setTimeout(() => {
                                                    navigate("/dashboard")
                                                }, 2000);
                                            })
                                            .catch(err => {
                                                console.log("something went wrong")
                                            })
                                    }
                                }}
                            >CHANGE</Button>
                        </Box>
                        <Snackbar
                            open={opensnack}
                            autoHideDuration={3000} // Alert will disappear after 6000ms (6 seconds)
                            onClose={handleClosesnack}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <Alert onClose={handleClosesnack} severity="success" sx={{ width: '100%' }}>
                                {message}
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            </div>
        </div>
    )
}
