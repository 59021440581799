import * as React from 'react';
import Button from '@mui/material/Button';
import { SocialIcon } from 'react-social-icons'
function Community(){
    return(
        <div className="CommunityContainer">
                  <div className="Community" data-aos="fade-up">
                    <h2>Become a Master Trader and join the Community</h2>
                    <p>If you are ready to become a master trader you can start now or join our community.</p>
                    <div>
                      <a href="#challenges"><Button  variant="contained" className="btn-start">Evaluation Plan</Button></a>
                    
                      <Button   variant="contained" className="btn-discord" onClick={()=>{window.open("https://discord.gg/nJWjX9AYzT","_blank")}}><SocialIcon style={{margin:"0px 10px"}} url="https://discord.gg/nJWjX9AYzT" />Join discord community</Button>
                    </div>
                  </div>
        </div>
    )
}
export default Community;
