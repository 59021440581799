import logo from "../img/logo.png";
import {Link} from 'react-router-dom';
import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion"
function Nav(props) {

    
        const drawerWidth = 240;
        const navItems = ['Challenge', 'Support', 'FAQ'];
        const { window } = props;
        const container = window !== undefined ? () => window().document.body : undefined;
        const [mobileOpen, setMobileOpen] = React.useState(false);
        const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
      };
      const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" sx={{ my: 2 }}>
          
          <motion.div
                animate={{ x: [0, 100, 0] }}>
                  <img width={50} height={50} src={logo} alt="#"/>
                </motion.div>
          </Typography>
          <Divider />
          <List>
            {navItems.map((item,id) => (
              <ListItem key={item} disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                <Link className="nav-mobile" to={item}><ListItemText  primary={item} /></Link>
                  
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      );
    return (
      <div className="nav">
         <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                  <div className="Navbar">
                      <Link to="/">Challenge</Link>
                      <Link to="/support">Support</Link>
                      <Link to="/">
                      <motion.div
                      animate={{ x: [0,-25,25,0] }}
                      transition={{ ease: "easeOut", duration: 2 }}
                      >
                        <img width={50} height={50} src={logo} alt="#"/>
                      </motion.div>
                      </Link>
                      <Link to="/faq">FAQ</Link>
                      <a href="https://www.multiverse-funding.com/Dashboard" target="_blank" rel="noreferrer"><Button className="btn-nav" variant="contained" >Dashboard</Button></a>
                    </div>
                </Typography>
                <a href="https://www.multiverse-funding.com/Dashboard" target="_blank" rel="noreferrer"><Button sx={{ mr: 2, display: { sm: 'none' } }} className="btn-navp" variant="contained" >Dashboard</Button></a>
              </Toolbar>
              
            </AppBar>
            
            <nav>
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
              >
                {drawer}
              </Drawer>
            </nav>
          </Box>
               </div>);
  }
  
  export default Nav;
  