import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Grid, Card, CardContent, Box } from '@mui/material';
import axios from 'axios';
import Localbase from 'localbase'
import { Snackbar, Alert } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function KYCForm() {
    const [selfie, setSelfie] = useState(null);
    const [preselfie, setPreSelfie] = useState(null);
    const [idDocument, setIdDocument] = useState(null);
    const [preidDocument, setPreIdDocument] = useState(null);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let url;
    if (process.env.NODE_ENV == 'production') {
        url = "https://www.multiverse-funding.com";
    } else {
        url = "http://localhost:8080";
        // URL = "https://www.multiverse-funding.com";
    }
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [progress, setProgress] = useState(0)
    let db = new Localbase('Mfundingdashboard')
    useEffect(() => {
        db.collection('user').get().then(users => {
            if (users.length !== 0) {
                setEmail(users[0].email)
            }
        })
    }, []);
    const handleSelfie = async (event) => {
        const formData = new FormData();
        formData.append('file', selfie);
        formData.append('email', email);
        try {
            if (selfie !== '' && email !== '') {
                setIsLoading(true)
                const response = await axios.post(`${url}/api/submit-selfie`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        const percentCompleted = Math.round((loaded * 100) / total);
                        setProgress(percentCompleted);
                    }
                });
                console.log('KYC Data Submitted Successfully:', response.data);
                setIsLoading(false)
                setSnackbarMessage(response.data.message);
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            }
        } catch (error) {
            setIsLoading(false)
            setSnackbarMessage(error.response ? error.response.data.message : 'Failed to submit KYC');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            console.log('Failed to submit KYC data:', error.response ? error.response.data : 'No response');
        }
    };

    const handleID = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', idDocument);
        formData.append('email', email);
        try {
            if (selfie !== '' && email !== '') {
                setIsLoading(true)
                const response = await axios.post(`${url}/api/submit-id`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        const percentCompleted = Math.round((loaded * 100) / total);
                        setProgress(percentCompleted);
                    }
                });
                setSnackbarMessage(response.data.message);
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                setIsLoading(false)
                console.log('KYC Data Submitted Successfully:', response.data);
            }

        } catch (error) {
            setIsLoading(false)
            setSnackbarMessage(error.response ? error.response.data.message : 'Failed to submit KYC');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            console.log('Failed to submit KYC data:', error.response ? error.response.data : 'No response');
        }
    };

    const handleFileChange = (fileType, event) => {
        const file = event.target.files[0];
        if (fileType === 'selfie') {
            setSelfie(file);
            setPreSelfie(URL.createObjectURL(file))
        } else if (fileType === 'idDocument') {
            setIdDocument(file);
            setPreIdDocument(URL.createObjectURL(file))
        }
    };

    const UploadArea = ({ onChange, previewUrl, label }) => (
        <Card variant="outlined">
            <CardContent>
                <label htmlFor={label}>
                    <input type="file" id={label} hidden onChange={onChange} />
                    <Box sx={{ minHeight: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', border: '2px dashed grey' }}>
                        {previewUrl ? (
                            <img src={previewUrl} alt={label} style={{ maxWidth: '100%', maxHeight: '140px' }} />
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                Click or Drag to Upload {label}
                            </Typography>
                        )}
                    </Box>
                </label>
            </CardContent>
        </Card>
    );

    return (
        <Paper elevation={3} style={{ padding: '20px', maxWidth: 700, margin: '20px auto' }}>
            <Typography variant="h6" align="center">
                KYC Verification
            </Typography>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='p'>Clear selfie without filter</Typography>
                        <UploadArea
                            onChange={(e) => handleFileChange('selfie', e)}
                            previewUrl={preselfie}
                            label="Selfie"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress sx={{ m: 1 }} variant="determinate" value={progress} />
                        <Button onClick={handleSelfie} fullWidth variant="contained" sx={{
                            m: 2,
                            backgroundColor: 'rgba(15, 10, 70, 1)',
                            '&:hover': {
                                backgroundColor: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
                            }
                        }}>
                            submit Selfie
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='p'>Accepted ID: passport/driving licence/gov ID</Typography>
                        <UploadArea
                            onChange={(e) => handleFileChange('idDocument', e)}
                            previewUrl={preidDocument}
                            label="ID Document"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress sx={{ m: 1 }} variant="determinate" value={progress} />
                        <Button onClick={handleID} fullWidth variant="contained" sx={{
                            m: 2,
                            backgroundColor: 'rgba(15, 10, 70, 1)',
                            '&:hover': {
                                backgroundColor: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
                            },
                        }}>
                            submit id
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <FullScreenLoader open={isLoading} />
            </form>
        </Paper>
    );
}

export default KYCForm;

function FullScreenLoader({ open }) {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
            {/* Optional: Adding a message or additional styling */}
            <Box sx={{ position: 'absolute', top: '60%', fontWeight: 'bold', fontSize: '1.2rem' }}>
                submitting
            </Box>
        </Backdrop>
    );
}