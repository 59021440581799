const general=[{
    title:"Terms and Conditions",
    description:[`These general terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the Multiverse-Funding.Com website (“Website”, “Service” or “Provider”) and any of its related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “Client”, “you” or “your”) and MULTIVERSE FD LTD (“MULTIVERSE-FUNDING.COM”, “WE”, “US” or “OUR”). By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such an entity to this Agreement, in which case the terms “User”, “you” or “your'' shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Website and Services. You acknowledge that this Agreement is a contract between you and MULTIVERSE-FUNDING.COM., even though it is electronic and is not physically signed by you, and it governs your use of this Website and our Services.`],
    list:[
        `1.	Accounts and Membership
        You must be at least 18 years of age to use our Website and Services.  By using the Website and Services, and by agreeing to this agreement you warrant that you are at least 18 years of age.  User attests that all information, including the name and personal information are true and correct and shall be updated by user if changes occur.  Users shall not sign up under any name except their legal name.  Clients shall not impersonate anyone. Clients shall not transfer the rights of their account to anyone else and there are no third-party beneficiaries to the services. Client certifies it is not prohibited by applicable law to the user and they are authorized to use our services and we are not responsible for the using of our service in any way that breaks applicable law to the user.  Users shall not share the account login information with anyone or participate in any type of account management services that involves copying another person’s trades.  User is responsible for any use associated with their account.  We will not be liable for any acts or omissions by the client, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration. 
        `,
        `We do not own any data, information or material (collectively, “Content”) that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor and review the Content on the Website submitted or created using our Services by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. You also grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.`,
        `You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.
        The fees for your Challenge are nominated in U.S. dollars. In the event of payment of the fee in any other currency than USD, the amount of the fee for the selected option shall be converted to USD using the exchange rate of your payment processor. Service charges are inclusive of all taxes. The Client is obliged to fulfill all of one’s tax obligations in connection with the use of our Services in accordance with applicable law, and in the event of an obligation, the Client is obliged to pay tax or other fees properly.
        You can pay the fee for the selected Funded Account Program by a payment card, or using other means of payment that the Provider currently offers on the Website. In the event of payment by a payment card or via any other express payment method, the payment shall be processed immediately. If you do not pay the amount on time, the Provider is entitled to cancel your order. Client bears all fees charged to Client by the selected payment service provider (according to the valid price list of the payment services provider) in connection with the transaction and the Client is obliged to ensure that the respective fee for the selected Funded Account Program is paid in full.
        `,
        `Accuracy of information
        Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or Services has been modified or updated.
        `,
        `Backups
        We perform regular backups of the Website and its Content and will do our best to ensure completeness and accuracy of these backups. In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.
        `,
        `Links to other resources
        Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. Some of the links on the Website may be “affiliate links”. This means if you click on the link and purchase an item, MULTIVERSE-FUNDING.COM. will receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website and Services. Your linking to any other off-site resources is at your own risk.
        `,
        `Prohibited uses
        In addition to other terms as set forth in the Agreement, you are prohibited from using the Website and Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Website and Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Website and Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Website and Services for violating any of the prohibited uses.
        `,
        `Cheating
        Any trading styles that are deemed as cheating are not allowed, this includes any style of demo account trading that would not result in profits in the real market, or would not be possible in real market trading conditions, for example, exploiting a delayed feed, HFT strategies, gap trading, latency arbitrage, Long short arbitrage, Reverse arbitrage, opposite account trading. Any trading activities that exploit the inefficiencies of Metatrader are all unacceptable trading methods.
        `,
        `“Intellectual Property Rights'' means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by MULTIVERSE-FUNDING.COM. or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with MULTIVERSE-FUNDING.COM. All trademarks, service marks, graphics and logos used in connection with the Website and Services, are trademarks or registered trademarks of MULTIVERSE-FUNDING.COM. or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Website and Services may be the trademarks of other third parties. Your use of the Website and Services grants you no right or license to reproduce or otherwise use any of MULTIVERSE-FUNDING.COM. or third party trademarks.`,
        `Disclaimer of warranty
        You agree that such Service is provided on an “as is” and “as available” basis and that your use of the Website and Services is solely at your own risk. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service unless stated otherwise. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.
        `,
        `11.	Limitation of liability
        To the fullest extent permitted by applicable law, in no event will MULTIVERSE-FUNDING.COM., its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of MULTIVERSE-FUNDING.COM. and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to MULTIVERSE-FUNDING.COM. for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.
        `,
        `12.	Indemnification
        You agree to indemnify and hold MULTIVERSE-FUNDING.COM. and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys’ fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website and Services or any willful misconduct on your part.
        `,
        `13.	Severability
        All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
        `,
        `14.	Assignment
        You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are free to assign any of its rights or obligations hereunder, in whole or in part, to any third party as part of the sale of all or substantially all of its assets or stock or as part of a merger.
        `,
        `15.	Changes and amendments
        We reserve the right to modify this Agreement or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Agreement on the Website.
        `,
        `16.	Acceptance of these terms
        You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services.
        `,
        `Contacting us
        If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may do so via sending an email to support@multiverse-funding.com
        `


    ]
}]




const OneStep=[{
    title:"Refund Policy",
    description:[`Upon official confirmation of payment for your evaluation plan, an email containing login details to access your evaluation will be dispatched. Following this email transmission, refunds will not be provided.`],
    list:[
        `In exceptional circumstances, should you notify us promptly and refrain from any trading activity within the initial 7-day period, we are prepared to collaborate with you and facilitate a refund. For assistance in such instances, please reach out to our live support.`,
        `Please note: Refunds are considered on a case-by-case basis and are not guaranteed after purchase.`,
        `Traders acknowledge that chargebacks will not be pursued once any activity, whether at the challenge or funded stage`,
        `Refunds for the challenge fee in our 1-Step program are not offered. Traders are exclusively rewarded for their performance during the funded stage, given the inclusion of High-Frequency Trading (HFT) in the challenge stage. For the 2-step program without the utilization of HFT, enabling passage through the challenge stage, a refund of up to 80% of the purchase fee is permissible upon the first payout. The remaining 20% of the fee is allocated for maintenance and is non-refundable.`,
        `t is incumbent upon you to acquaint yourself with this refund policy. By placing an order for any of our challenges, you signify that you have reviewed this refund policy and fully consent to its terms.`,
    ]
}
]


const TwoStep=[
    {
        title:"Privacy Policy",
        description:[`The MULTIVERSE FD LTD (“MULTIVERSE FUNDING”, “us”, “we” and “our”) is committed to safeguarding your privacy online or otherwise and the success of our business depends upon our ability to maintain the trust of our customers. This Privacy Policy aims to provide a summary of the type of Personal Data that we collect of our customers, how such information is used, transferred/disclosed and stored. The policy does not cover any external third-party websites that may be accessed through links provided on our website.
        “Personal Data” has the meaning set out in the Personal Data Protection Act 2012 (“PDPA”).
        Any reference to “MULTIVERSE FUNDING”, “us”, “we” and “our“ in this privacy policy is a reference to MULTIVERSE FD LTD as the context requires unless otherwise stated.
        Similarly, any reference to ”you”, “your”, “yours”, “yourself” or “Customer” in this privacy policy is a reference to any of our customers and potential customers as the context requires, unless otherwise stated.
        `],
        list:[
            `COLLECTION OF PERSONAL DATA
            Generally, we collect personal data that is linked to an individual. We obtain and collect the data about you directly from you in connection with your use of our services. This includes, in particular:
            i. Data that you provide to us yourself, especially when you register on the website, enter information into your user account, order services, participate in our events or projects, or when you communicate with us through customer support or social networks. For example, your name, identification number, residential address, date of birth, gender, nationality, phone number,
            email address, location data etc. If you are an entrepreneur, payment data, such as bank details and other data, such as records of communication between you and our company or details of any warranty claims. This is not an exhaustive list and we may collect other types of personal data from you.
            ii. Apart from Personal data, we may collect other types of information which are not linked to an individual and which are anonymous. These are data that we automatically collect when you use our services. These are data about your device (such as IP address, device type, operating system, browser used, connection provider); website usage data (such as date, time and duration of visit, country from which you visit the website, website browsing history), and data on the use of services (such as the login and logout information, your account settings, value of your fictitious capital, your account currency, business strategy).
            
            Some data about you is provided to us by third parties, such as providers of trading platforms that you choose for use of our services, and social network operators if you decide to link you social networking accounts to an account on the website. This includes:
            iii. Data about the simulated transactions you have completed (such as the type of financial instrument, time of opening and closing the position, amount of investment, profit and loss) and data from social networks (user name, profile picture, e-mail address associated with the account on the social network). For information on how your personal data are processed by trading
            platform, providers and social network operators for their own purposes, please refer to the privacy of those parties.
            `,
            `USE, DISCLOSURE AND/OR PROCESSING OF PERSONAL DATA
            We may hold and retain information about you for various purposes based on different reasons.
            · Registration and user account
            In order to use the services, you must register on the website and create your account, and for this purpose we process your personal data.
            Personal data: identification and contact data; we may also process your data related to the settings of your user account.
            Legal ground for the processing: Processing is necessary for the performance of the contract, and the provision of this personal data is required, as we cannot register your user account without them.
            
            · Provision of services and exercise of rights and obligations under the contract between us and the customer
            In order to be able to provide you with our services, i.e. in particular to provide you with the relevant access, tools and support, and to carry out trades, we need to process your personal data.
            For this purpose, the following shall apply:
            Personal data: identification and contact data and, in the case of paid services, also payment data, data on the use of the services, data on the simulated trades and, if you decide to link your social networks accounts with the account on the website, also data from social networks.
            Legal ground for the processing: Processing is necessary for the performance of the contract on the basis of which we provide our services, or on the basis of our legitimate interest. After we stop providing you with services, we process personal information for a limited period of time. Such processing is necessary for the purposes of exercising and protecting our rights based on our
            legitimate interest.
            
            · Marketing communication about our services and products or about events in which we take part
            If you are our current or past customer, we may from time to time send you marketing communications to the extent to which you give us your consent. Each of the e-mails sent will be marked as a business communication and each of them will contain a link with the help of which you can easily unsubscribe from the business communications. For this purpose, the following shall
            apply:
            Personal data: identification and contact data.
            Legal ground for the processing: If we have provided you with our services, we may from time to time send you marketing communications based on our legitimate interest, namely keeping in touch with our customers. If you give us your consent to process your data for marketing purposes, then we process your data on the basis thereof.
            
            · Customer support and communication
            If you use our customer support services, we will process your personal data. For this purpose, the following shall apply:
            Personal data: identification and contact data, other data and data from social networks, if you contact us through social networks.
            Legal ground for the processing: Processing is based on our legitimate interest, namely in maintaining customer satisfaction and supporting the use of our services.
            
            · Improving services
            If you use some of our services, we will process your personal data in order to develop, test and enhance the services and improve their security. For this purpose, the following shall apply:
            Personal data: identification and contact data, data about your device, website usage data, data on the use of the services, and data on the simulated trades.
            Legal ground for the processing: Processing is based on our legitimate interest, namely in improving our services.
            
            · Analytics
            To obtain information about how our website and our services are used, we analyze customer behavior. For this purpose, the following shall apply:
            Personal data: identification and contact data, payment data, data about your device, website usage data, data on the use of the services, and data on the simulated trades.
            Legal ground for the processing: Processing is based on our legitimate interest, namely in ascertaining customer behavior and estimating customer preferences.
            `,
            `STORING AND SECURITY OF PERSONAL DATA
            We process your personal data in an encrypted database on computers and other devices. In order to ensure protection against unauthorized access or unauthorized alteration, disclosure or destruction of the data that we collect and process, we have adopted organizational and technical measures to secure them, which we strictly adhere to.
            `,
            `. RETENTION
            Personal Data is held for as long as the purpose for which it was collected remains and until retention is no longer legally required nor necessary for business purposes. MULTIVERSE FUNDING shall cease to retain Personal Data or remove the means by which the Personal Data can be associated with particular customers, as soon as it is reasonable to assume that the purpose for which that Personal Data was collected is no longer valid and retention is no longer necessary for legal or business purposes
            `,
            `TRANSFER OF PERSONAL DATA OVERSEAS
            In certain circumstances, we may need to transfer your personal data outside of United Kingdom (for example, data storage, data management and/or consolidation purposes), whether to our parent company, group companies, business partners or third-party service providers or simply to data storage facilities, we shall do so in accordance with the PDPA and ensure that the standard of protection applied to Personal Data so transferred is comparable to the protection required under the PDPA.
            `,
            `. CONSENT
            MULTIVERSE FUNDING (including its employees, directors and/or representatives) is required by law to keep Personal Data strictly private and confidential, save and except as set out in this Privacy Policy, we will not disclose any Personal Data without having obtained your prior consent to do so unless otherwise permitted by law.
            Generally, when we request for Personal Data in the course of providing you a service and/or product and where you provide us with such Personal Data, there is implied consent that you agree to provide us your with Personal Information in order for us to provide you with the requested service and/or product.
            In some circumstances and as required by applicable law, we will seek your express consent when collecting your Personal Information. This is particularly where fresh content is required for a new purpose in which your Personal Information will be used.
            If you do not consent to provide the requisite personal data that we need at the point of application or in the course of maintaining your account(s)with us, we may not be able to open an account or provide specific service or product that you require. You are entitled to withdraw your consent for the collection of personal data by sending an email to Support@multiverse-funding.com with the subject titled “Withdrawal of Consent Form”. Upon receiving the email, we will send you the relevant form for the necessary action. However, do note that by doing this, it may affect our ability to maintain your account(s) with us.
            `,
            `ACCURACY AND CORRECTION
            MULTIVERSE FUNDING endeavors to ensure that your Personal Data is as accurate and up-to-date as possible. To ensure that we hold accurate and current information, we ask you to promptly notify us of any changes to the personal data we hold about you. You may contact us at any time if you wish to correct, amend or update your Personal Data by emailing us at support@multiverse-funding.com.
            `,
            `ACCESS RIGHTS
            Under the Act, you have the right to obtain a copy of Personal Data which we hold about you and entitled to know how your Personal Data has been used or disclosed in the past one year. A request can be made by emailing us at support@multiverse-funding.com with the subject titled “Request for Information”.
            Your request will be acknowledged and acted upon generally within thirty (30) days of receipt of such request. If for any reason, we are unable to accede to your request, you will be notified accordingly. MULTIVERSE FUNDING is entitled to charge a reasonable administrative fee to cover the processing cost for the request. The actual fee will be provided to you after we have received your request as it will depend on the data requested.
            `,
            `SECURITY SAFEGUARDS
            We have implemented stringent measures to secure and protect your information. However, you should be aware that the use of the Internet is not entirely secure and for this reason we cannot guarantee the security or integrity of any personal data which is transferred from you or to you via the Internet.
            `,
            `COOKIES
            A cookie is a text file made up of letters and numbers that allow us to distinguish your browser/device from another user’s browser/device. This text file is stored on your computer’s hard disk for the purpose of keeping records when you visit a website.
            We use cookies to collect information. When cookies are used by us, they collect statistical and factual information about how you use our services. Most internet browsers are set up to accept cookies. If you do not wish to receive cookies, you may be able to change the settings of your browser to refuse all cookies or to have your computer notify you each time a cookie is sent to it, and thereby give yourself the choice whether to accept it or not. However, this may impair the quality of the services that we provide to you in relation to your account.
            `,
            `GOVERNING LAW
            MULTIVERSE FD LTD is a United Kidgdom registed Company that operates under the name Multiverse Funding, this Privacy Policy has been drafted solely in accordance with the laws of United Kingdom. We do not represent or warrant that this Privacy Policy complies with the privacy laws of any other jurisdiction and accordingly, you shall not construe this Privacy Policy as such.
            `,
            `EUROPEAN UNION GENERAL DATA PROTECTION REGULATION (EU GDPR)
            In addition to obligations under the PDPA, we strive to comply with its obligations to individuals in the EU as set out in the EU GDRP. In the event that the EU GDRP requires a higher level of protection for the personal data of individuals in EU than the PDPA, such stricter requirements shall be complied with.
            Please refer to the GDPR website for more information:https://eugdpr.org
            `,
            `CONTACT US
            If you have any comments and/or questions about this Privacy Policy or if you wish to give specific feedback relating to our collection, use and/or disclosure of your Personal Data, please contact us as follows:
            Subject: Privacy Policy
            Attention: Data Protection Officer
            Email: support@multiverse-funding.com
            In applying for, using or continuing to use our services, you shall be deemed to have accepted and consented to the terms in this Privacy Policy. If you do not consent to the terms herein, kindly contact us at the abovementioned contact details.
            `,
            `AMENDMENTS TO THIS PRIVACY POLICY
            This Privacy Policy will be reviewed from time to time to take account of amendments to the Act, and other applicable new laws and technology, changes to our operations and practices and to make sure it remains appropriate to the changing environment. Amendments to this Privacy Policy will be effective from the time they are posted on our website and your continued use of this website will constitute acceptance to any amendments to this Privacy Policy. We advise you to check the website regularly for any amendments to this Privacy Policy.
            `,
            `DISCLAIMER
            Our website may contain links to external third party websites. Please note that third-party websites are not covered by our Privacy Policy. You are advised to read the privacy statement of each website that you visit, which collects personal data
            `
        ]
    }
]
export {general,OneStep,TwoStep};
