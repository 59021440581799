import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, TextField, DialogTitle, DialogContent, Snackbar } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
function ReviewKYC() {
    let url;
    if (process.env.NODE_ENV == 'production') {
        url = "https://www.multiverse-funding.com";
    } else {
        url = "http://localhost:8080";
        // URL = "https://www.multiverse-funding.com";
    }
    const [users, setUsers] = useState([])
    const [update, setUpdate] = useState(false)
    useEffect(() => {
        axios.get(`${url}/api/get-kyc-request`)
            .then(res => {
                setUsers(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [update]);
    const handleApprove = (userEmail) => {
        axios.post(`${url}/api/approve-kyc`, { email: userEmail })
            .then(res => {
                setUpdate(!update)
                setSnackbar({ open: true, message: res.data.message, severity: 'success' });
            })
            .catch(err => {
                console.log(err)
                setSnackbar({ open: true, message: "error approving KYC, check logs", severity: 'error' });
            })
    };
    const [selectedUser, setselectedUser] = useState('')

    const [openModal, setOpenModal] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');

    const handleOpenModal = (imageUrl) => {
        console.log(imageUrl)
        setSelectedImageUrl(`https://astucefx.net/${imageUrl}`);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const [open, setOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('')
    const handleClose = () => {
        setOpen(false);
    };
    const handleReject = () => {
        axios.post(`${url}/api/reject-kyc`, { email: selectedUser.email, reason: rejectionReason, selfie: selectedUser.documents.selfie, ID: selectedUser.documents.ID })
            .then(res => {
                setSnackbar({ open: true, message: res.data.message, severity: 'success' });
                setUpdate(!update)
                setOpen(false);
                setRejectionReason('')
            })
            .catch(err => {
                console.log(err)
                setSnackbar({ open: true, message: "error approving KYC, check logs", severity: 'error' });
            })
    };
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">DOB</TableCell>
                        <TableCell align="right">Country</TableCell>
                        <TableCell align="right">Documents</TableCell>
                        <TableCell align="right">Actions</TableCell>
                        <TableCell align="right">Is Verified?</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow
                            key={user.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{user.firstName}{user.lastName}</TableCell>
                            <TableCell align="right">{user.email}</TableCell>
                            <TableCell align="right">{user.DOB}</TableCell>
                            <TableCell align="right">{user.country}</TableCell>
                            <TableCell align="right">
                                <IconButton onClick={() => handleOpenModal(user.documents.selfie)}>
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton onClick={() => handleOpenModal(user.documents.ID)}>
                                    <VisibilityIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="right">
                                <Button onClick={() => handleApprove(user.email)} startIcon={<CheckIcon />} color="success">Approve</Button>
                                <Button onClick={() => {
                                    setselectedUser(user)
                                    setOpen(true)
                                }} startIcon={<CloseIcon />} color="error">Reject</Button>
                            </TableCell>
                            <TableCell align="right">
                                {user.isVerified ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ImageModal
                open={openModal}
                handleClose={handleCloseModal}
                imageUrl={selectedImageUrl}
                title="KYC Selfie"
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">Reject KYC</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="rejection-reason"
                        label="Rejection Reason"
                        type="text"
                        fullWidth
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleReject} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </TableContainer>
    );
}

export default ReviewKYC;

const ImageModal = ({ open, handleClose, imageUrl, title }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="image-dialog-title"
            aria-describedby="image-dialog-description"
            style={{ width: 1000, height: 1000, margin: "auto" }}
        >
            <DialogActions>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <div style={{ overflow: 'hidden', cursor: 'zoom-in' }}>
                <img src={imageUrl} alt={title} style={{ width: '100%', transition: 'transform 0.25s ease', '&:hover': { transform: 'scale(1.5)' } }} />
            </div>
        </Dialog>
    );
};

