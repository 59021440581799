import payoutIcon from "../img/iconpayout.png";
function PayoutSchedules(){
    return(
        <div className="PayoutScheduleContainer">
                <div className="PayoutSchedule"  data-aos="fade-up">

                    <div className="payoutWriting">
                    <h2>Payout Schedule</h2>
                    <dl>
                    <dt>INSTANT FUNDING (HFT series)</dt>
                    <dd>The first withdrawal split is 50% of the virtual profit made. However, the withdrawal split will be increased after every successful withdrawal up to 100%.<br/><br/>
                    </dd>

                    <dt>2 step evaluation</dt>
                    <dd>The first withdrawal split is 80% of the virtual profit made. However, the withdrawal split will be increased after every successful withdrawal up to 100%. </dd>

                    </dl>
                    <p>Once you made profit on the Master Trader Stage and completed the requirements for payout you may request a withdrawal of the virtual money made.
                    After a minimum of 5 trading days and 21 calendar days since first trade a payout can be requested.</p>
                    </div>

                    <div className="imgpayout">
                    <img src={payoutIcon} alt="#"/>
                    </div>
                </div>
        </div>
    )
}
export default PayoutSchedules;
