import React, { useState, useEffect } from 'react';
import {
    Box, TextField, Button, Avatar, Grid, Typography, Paper, Badge, Tooltip, MenuItem, Select, FormControl,
    InputLabel, Snackbar, Alert, CircularProgress
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import Localbase from 'localbase'

export default function Setting() {
    let db = new Localbase('Mfundingdashboard')
    let URL
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
        // URL = "https://www.multiverse-funding.com";

    }
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        dateOfBirth: '',
        isVerified: true
    });
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [countries, setCountries] = useState([]);
    const [loadingpw, setLoadingpw] = useState(false);
    const [updated, setupdated] = useState(false);
    const [loadingp, setLoadingp] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    useEffect(() => {
        axios.get('https://restcountries.com/v3.1/all')
            .then(response => {
                const countryNames = response.data.map(country => ({
                    label: country.name.common,
                    code: country.cca2
                }));
                setCountries(countryNames);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);

    const handleInputChange = (event, field) => {
        setUserData({ ...userData, [field]: event.target.value });
    };

    const handlePasswordChange = (event, field) => {
        setPasswordData({ ...passwordData, [field]: event.target.value });
    };

    const saveProfileChanges = () => {
        setLoadingp(true);
        axios.post(`${URL}/api/updateProfile`, userData)
            .then(response => {
                db.collection('user').delete().then(e => {
                    db.collection('user').add(response.data.user).then(e => {
                        setupdated(!updated)
                    })
                        .catch(err => {
                            console.log("failed to logout")
                        })
                }).catch(err => { console.log("failed to logout") })
                setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
            })
            .catch(error => {
                setSnackbar({ open: true, message: 'Error updating profile', severity: 'error' });
            })
            .finally(() => {
                setLoadingp(false);
            });
    };

    const updatePassword = () => {
        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setSnackbar({ open: true, message: 'Passwords do not match', severity: 'error' });
            return;
        }
        setLoadingpw(true);
        axios.post(`${URL}/api/updatePassword`, {
            email: userData.email,
            currentPassword: passwordData.currentPassword,
            newPassword: passwordData.newPassword
        })
            .then(response => {
                setSnackbar({ open: true, message: 'Password changed successfully', severity: 'success' });
            })
            .catch(error => {
                setSnackbar({ open: true, message: error.response.data.error, severity: 'error' });
            })
            .finally(() => {
                setLoadingpw(false);
            });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };
    useEffect(() => {
        db.collection('user').get().then(users => {
            if (users.length !== 0) {
                axios.post(`${URL}/api/findme`, { email: users[0].email })
                    .then(res => {
                        console.log(res.data.user.DOB)
                        setUserData({
                            firstName: res.data.user.firstName,
                            lastName: res.data.user.lastName,
                            email: res.data.user.email,
                            country: res.data.user.country,
                            dateOfBirth: res.data.user.DOB,
                            isVerified: res.data.user.isVerified ? res.data.user.isVerified : false
                        })
                    })
                    .catch(err => {
                        setUserData({
                            firstName: users[0].firstName,
                            lastName: users[0].lastName,
                            email: users[0].email,
                            country: users[0].country,
                            dateOfBirth: users[0].DOB,
                            isVerified: users[0].isVerified ? users[0].isVerified : false
                        })
                    })

            }
        })
    }, [updated]);
    return (
        <div>
            <Box sx={{ maxWidth: 600, margin: 'auto', padding: 3, backgroundColor: 'white', borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom>Profile</Typography>

                {/* Personal Information Section */}
                <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <Tooltip title={userData.isVerified ? "Verified User" : "You need to verify your identity"} arrow>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    badgeContent={userData.isVerified ? <CheckCircleIcon sx={{
                                        color: 'rgba(15, 10, 70, 1)',
                                        '&:hover': {
                                            color: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
                                        },
                                    }} /> : <CancelIcon color="error" />}
                                >
                                    <Avatar alt={userData.firstName} src="/path/to/image.jpg" sx={{ width: 56, height: 56 }} />
                                </Badge>
                            </Tooltip>
                        </Grid>
                        {/* Input fields for user data */}
                        {Object.keys(userData).map((key, index) => {
                            // Conditionally rendering components based on the key
                            if (key === 'country') {
                                // Render Select component for country
                                return (
                                    <Grid item xs={12} key={key}>
                                        <FormControl fullWidth>
                                            <InputLabel id="country-select-label">Country</InputLabel>
                                            <Select
                                                labelId="country-select-label"
                                                id="country-select"
                                                value={userData.country}
                                                label="Country"
                                                onChange={(e) => handleInputChange(e, 'country')}
                                            >
                                                {countries.map((country) => (
                                                    <MenuItem key={country.code} value={country.code}>{country.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                );
                            }
                            else if (key !== 'isVerified') {
                                // Render TextField for all other fields except 'isVerified'
                                return (
                                    <Grid item xs={12} sm={key === 'email' || key === 'dateOfBirth' ? 12 : 6} key={key}>
                                        <TextField
                                            label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                                            type={key === 'dateOfBirth' ? 'date' : 'text'}
                                            value={userData[key]}
                                            onChange={(e) => handleInputChange(e, key)}
                                            fullWidth
                                            InputLabelProps={key === 'dateOfBirth' ? { shrink: true } : undefined}
                                            disabled={key === 'email'}
                                        />
                                    </Grid>
                                );
                            }
                            return null; // Return null for keys that shouldn't generate components (like 'isVerified')
                        })}
                        <Grid item xs={12}>
                            <Button variant="contained" sx={{
                                backgroundColor: 'rgba(15, 10, 70, 1)',
                                '&:hover': {
                                    backgroundColor: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
                                },
                            }} fullWidth onClick={saveProfileChanges} disabled={loadingp}>
                                {loadingp ? <CircularProgress size={24} /> : 'Save Changes'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Change Password Section */}
                <Paper variant="outlined" sx={{ padding: 2 }}>
                    <Typography variant="h6" gutterBottom>Change Password</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField label="Current Password" type="password" value={passwordData.currentPassword} onChange={(e) => handlePasswordChange(e, 'currentPassword')} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="New Password" type="password" value={passwordData.newPassword} onChange={(e) => handlePasswordChange(e, 'newPassword')} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Confirm New Password" type="password" value={passwordData.confirmPassword} onChange={(e) => handlePasswordChange(e, 'confirmPassword')} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" sx={{
                                backgroundColor: 'rgba(15, 10, 70, 1)',
                                '&:hover': {
                                    backgroundColor: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
                                },
                            }} fullWidth onClick={updatePassword} disabled={loadingpw}>
                                {loadingpw ? <CircularProgress size={24} /> : 'Update Password'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <div>
                {/* Snackbar for displaying messages */}
                <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}
