import Nav from "../navbar/Nav";
import * as React from 'react';
import Footer from '../footer/Footer';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import './affliate.css'

function DashbAdminAffiliate(){
    const[dashboard,setDashboard]=React.useState(false)
    const [success, setSuccess] = React.useState(false);
    const [email,setEmail]=React.useState("")
    const [password,setpassword]=React.useState("")
    const[IsEmpty,setIsEmpty]=React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    //hook for login or sign up 
    const [login,setLogin]=React.useState(false);
    //show pw/hide
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    //loading screen 
    const [loading, setLoading] = React.useState(false);
    const buttonSx = {
        ...(success && {
          bgcolor: green[500],
          '&:hover': {
            bgcolor: green[700],
          },
        }),
      };
      const handleButtonClick = () => {
        if (!loading) {
          setSuccess(false);
          setLoading(true);
        }
      };
      const [Users,setUsers]=React.useState([])
    //   const [cryptoAddress,setCryptoAddress]=React.useState("")
      const getList=()=>{
        axios.get("https://www.multiverse-funding.com/api/payoutpending")
        .then(a=>{
            setUsers(a.data.users)
                // console.log(a.data.users)
        })
        .catch(e=>{
            alert("something went wrong")
        })
      }
    //   React.useEffect(()=>{
        
    //   },[])
    // getList()
    return(
        <div className='Affliate-container'>
            <Nav/>
            <div className='contain-affliate' >
            <div className="card">
            <div className="content">
                {dashboard===false?
                <div class="back">
                <div class="back-content">
                      <h1>Login as an affiliate</h1>
                                    <div className='login-field-affliate'>
                                        <AccountCircle sx={{ color: IsEmpty?"red":"white", mr: 1, my: 0.5,width:"10%"}} />
                                        <input value={email} onChange={(e)=>{setEmail(e.target.value.toLowerCase().trim())}}  className='affliate-email-login' placeholder="Email" variant="outlined" />
                                    </div>

                                    <div className='login-field-affliate'>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            sx={{ color: IsEmpty?"red":"white", mr: 1, my: 0.5, width:"10%"}}
                                            >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        <input value={password} onChange={(e)=>{setpassword(e.target.value)}}  className='affliate-pw-login' type={showPassword ? 'text' : 'password'} placeholder="Password" variant="outlined" />
                                            <Box sx={{ display: 'flex', alignItems: 'center',position: 'relative',m: 1,width:"19%" }}>
                                                <Button 
                                                sx={buttonSx}
                                                disabled={loading} className='login-btn-affliate' variant="contained"
                                                onClick={()=>{
                                                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                    getList()
                                                    if(email.match(validRegex) || password){
                                                        handleButtonClick()
                                                        setIsEmpty(false)
                                                        
                                                        axios.post("https://www.multiverse-funding.com/api/login",{email,password})
                                                        .then((a)=>{
                                                          // console.log(a.data)
                                                            if(a.data.err){
                                                                console.log("wrong")
                                                            }else{

                                                              setDashboard(true)         
                                                            }
                                                            setSuccess(true);
                                                            setLoading(false);
                                                        })
                                                        .catch( (error) =>{
                                                            console.log(error);
                                                        });
                                                    }else{
                                                        setIsEmpty(true)
                                                    }
                                                }}
                                                >Login</Button>
                                                {loading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                                }}
                                            />
                                            )}
                                        </Box>
                                    </div>
                        </div>
                        </div>:


                    <div className="back">
                        <div className="back-content">
                            <h1>pending payout</h1>
                            <table>
                                <th>username</th>
                                <th>USDT TRC20 address</th>
                                <th>commission</th>
                                <th>Approve</th>
                                {Users.map((item,id)=>
                                    <tr key={id}>
                                        <td>{item.username}</td>
                                        <Button onClick={()=>{

                                            navigator.clipboard.writeText(item.CryptoAddress).then(
                                                function() {
                                                    alert("Text copied to clipboard successfully!");
                                                },
                                                function(err) {
                                                    console.error("Failed to copy text: ", err);
                                                }
                                                );
                                        }}>{item.CryptoAddress}</Button>
                                        <td>${item.Commission}</td>
                                        <Button onClick={()=>{
                                        var nowDate = new Date(); 
                                        var date = nowDate.getFullYear()+'/'+(nowDate.getMonth()+1)+'/'+nowDate.getDate();
                                       
                                            axios.post(`https://www.multiverse-funding.com/api/deletepayoutrequest`,{username:item.username})
                                            .then(a=>{
                                                // console.log(a.data.success)
                                                    getList()
                                                    if(a.data.success){
                                                        axios.post(`https://www.multiverse-funding.com/api/payout`,{username:item.username,PayoutHistory:{date:date,amount:item.Commission}})
                                                        .then(e=>{
                                                            if(e.data.modifiedCount===1){
                                                                alert("payout sent")
                                                            }
                                                        })
                                                        .catch(err=>{
                                                            alert("something went wrong")
                                                            console.log(err)
                                                        })
                                                }
                                                
                                                
                                            })
                                            .catch(event=>{
                                                console.log(event)
                                            })
                                            
                                        }}>Approve</Button>
                                    </tr>)}
                            </table>
                        </div>

                    </div>}

                    </div>
                    </div>
                    </div>

                    
                    
            <Footer/>
        </div>
    )
}
export default DashbAdminAffiliate;
