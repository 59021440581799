import * as React from 'react';
import usIcon from "../img/us.png";
import Button from '@mui/material/Button';
function Affiliate(){
    return(
        <div className="WhyContainer">
                <div className="PayoutSchedule"  data-aos="fade-up">

                    <div className="payoutWriting">
                    <h2>Affiliate Program</h2>
                    <dl>
                    <dt><b>10% Commission</b></dt>
                    <dd>Join our Affiliate program and introduce your friends to Multiverse Funding. When they purchase a challenge using your unique affiliate code, you'll earn a 10% commission from the challenge fee. You can request a payout once your referral commissions reach $100.<br/><br/>
                    </dd>

                    <dt><b>5% Discount</b></dt>
                    <dd>As an affiliate, your clients will receive a 5% discount when they purchase a challenge using your referral code.</dd>
                    </dl>
                    </div>

                    <div className="imgpayout">
                    <img src={usIcon} alt="#"/>
                    <a target="_blank" rel="noreferrer" href="https://www.multiverse-funding.com/affiliate"><Button   variant="contained" className="btn-discord"> Join Affiliate Program</Button></a>
                    </div>
                    
                </div>
                
        </div>
    )
}
export default Affiliate;
