import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Footer from '../footer/Footer';
import Nav from '../navbar/Nav';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
const Successfull = () => {
    const[State,setState]=useState("")
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const payment_intent = urlParams.get('payment_intent');
    useEffect(()=>{
        axios.post("https://www.multiverse-funding.com/api/check_paymentstatus",{
            payment_intent
        })
        .then(e=>{
            // console.log(e.data.paymentID)
            // console.log(e.data.retrieved)
            if(e.data.paymentID==="succeeded" || e.data.retrieved ==true){
                setState("created")
            }
        })
        .catch(e=>{
            alert('something went wrong')
        })
    },)

  return (
    <div>
        <Nav/>
        <div className='successfully'>
                {State==="created"?<div>
                    your trading account has already been created, kindly login to your dashboard
                    <a href="https://dashboard.multiverse-funding.com/" target="_blank" rel="noreferrer"><Button className="btn-navp" variant="contained" >Dashboard</Button></a>
                </div>:
                <div>
                    Your trading account is being created
                    <CircularProgress />
                </div>
                }
        </div>
        <Footer/>
    </div>
    
  );
};


export default Successfull;

