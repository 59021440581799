import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    size: 0,
    price:0,
    step:0,
    platform:""
  },
  reducers: {
    addsize: (state, action) => {
      state.size = action.payload
    },
    addprice: (state, action) => {
        state.price = action.payload
      },
    addstep: (state, action) => {
        state.step = action.payload
      },
    addPlatform: (state, action) => {
        state.platform = action.payload
      }
  },
})

export const { addsize, addprice, addstep,addPlatform } = counterSlice.actions
export const selectsize = (state) => state.counter.size
export const selectprice = (state) => state.counter.price
export const selectstep = (state) => state.counter.step
export const selectplatform = (state) => state.counter.platform

export default counterSlice.reducer
