import "../checkout.css"
import React, {useState} from 'react';
import {PaymentElement,useStripe,useElements,LinkAuthenticationElement} from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import {selectsize,selectprice,selectstep,selectplatform} from '../../redux/counterSlice';
import CircularProgress from '@mui/material/CircularProgress';
const CheckoutForm = (props) => {
  const id = props.id;
const client_secret = props.client_secret;
    //get acc size, price and step from home page
    const size = useSelector(selectsize);
    const price = useSelector(selectprice);
    const step = useSelector(selectstep);
    const platform = useSelector(selectplatform);
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const handleSubmit = async (event) => {
      
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }
    setClicked(true)
    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret:client_secret,
      confirmParams: {
        return_url: 'https://www.multiverse-funding.com/success/',
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      alert("error")
    } else {
      // alert("payment successfully made")
      console.log("payment was made")
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  const[clicked,setClicked]=useState(false)
  return (
    <div className='stripePayment'>
      <h4> ${size.toLocaleString()} {step} Challenge {platform}</h4>
      <h4>price:${price}</h4>
        <form onSubmit={handleSubmit}>
        <linkAuthenticationElement
        // Optional prop for prefilling customer information
        options={{
          defaultValues: {
            email: 'astucefx@gmail.com',
          },
        }}
      />
          <PaymentElement />
          <Button style={{marginTop:"10px"}} variant='contained' className='apply-coupon cryptopaymentbtn' type="submit" disabled={!stripe || !elements}>
            Purchase Now
            {clicked===false?"":<CircularProgress />}
            
          </Button>
          {/* Show error message to your customers */}
          {errorMessage && <div>{errorMessage}</div>}
        </form>
    </div>
    
  );
};


export default CheckoutForm;

