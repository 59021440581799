import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Box, Typography } from '@mui/material';
import axios from 'axios';

const CreateAccount = () => {
    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
    }
    const [email, setEmail] = useState('');
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    useEffect(() => {
        axios.get(`${URL}/api/get-plans`)
            .then(response => {
                console.log("plan", response)
                setPlans(response.data.plan);
                if (response.data.plan.length > 0) {
                    setSelectedPlan(response.data[0]._id);
                }
            })
            .catch(error => console.error('Error fetching plans:', error));
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (email) {
                axios.post(`${URL}/api/search`, { email }) // POST request with email in body
                    .then(response => {
                        setUserOptions(response.data);
                        if (response.data.length === 1) {
                            setSelectedUser(response.data[0].email);
                        } else {
                            setSelectedUser('');
                        }
                    })
                    .catch(error => console.error('Error fetching users:', error));
            }
        }, 500); // Debounce time is 500ms

        return () => clearTimeout(timer);
    }, [email]); // Effect runs whenever the email state changes

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${URL}/api/create-trading-acccount`, { email: selectedUser, planId: selectedPlan })
            .then(response => {
                alert("account created successfully")
            })
            .catch(error => {
                console.error('Error creating account:', error);
                alert('Failed to create account.');
            });
    };

    return (
        <div className='overview-container'>
            <Box sx={{ maxWidth: 500, mx: 5 }}>
                <Typography variant="h5" gutterBottom>
                    Create Trading Account
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="User Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                {userOptions.length > 0 && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="user-label">Select User</InputLabel>
                        <Select
                            labelId="user-label"
                            id="user-select"
                            value={selectedUser}
                            label="Select User"
                            onChange={e => setSelectedUser(e.target.value)}
                        >
                            {userOptions.map((user) => (
                                <MenuItem key={user._id} value={user.email}>
                                    {user.firstName + " " + user.lastName || user.email}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <FormControl fullWidth margin="normal">
                    <InputLabel id="plan-label">Select Plan</InputLabel>
                    <Select
                        labelId="plan-label"
                        id="plan-select"
                        value={selectedPlan}
                        label="Select Plan"
                        onChange={e => setSelectedPlan(e.target.value)}
                    >
                        {plans !== null && plans.map((plan) => (
                            <MenuItem key={plan._id} value={plan._id}>
                                {plan.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                >
                    Create Account
                </Button>
            </Box>
        </div>

    );
};

export default CreateAccount;
