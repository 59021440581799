import Nav from "../navbar/Nav";
import * as React from 'react';
import Footer from '../footer/Footer';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import './lot.css'

function Lotsize(){
  const[lotsize,setLotsize]=React.useState()
  const[Positions,setPositions]=React.useState()
  const[Max,setMax]=React.useState()
  const[Min,setMin]=React.useState()
  const[average,setAverage]=React.useState()
  const[calculated,setCalculated]=React.useState(false)
    return(
        <div className='Affliate-container'>
          <Nav/>
          <div className="lotsize-input">
            <h3>Calculate your lot size consistency for your Multiverse funding 1 step account</h3>
            {calculated===true?<h4>your consistency range is from {Min.toFixed(2)} to {Max.toFixed(2)}</h4>:""}
            {calculated===true?<h4>any position outside of the consistency range will be deducted from payout</h4>:""}
            {calculated===true?<ul>
              <li>Your Minimum lot size is <span style={{color:"red"}}>{Min.toFixed(2)}</span></li>
              <li>Your average lot size is <span style={{color:"yellow"}}>{average.toFixed(2)}</span></li>
              <li>Your Maximum lot size is <span style={{color:"blue"}}>{Max.toFixed(2)}</span></li>
            </ul>:""}
            
            <div>
                <TextField
                    id="outlined-password-input"
                    label="Total Lots size"
                    type="text"
                    className="inputEmail"
                    value={lotsize}
                    // error={checker===true?"error":""}
                    onChange={(e)=>{
                      setLotsize(e.target.value)
                    }}
                  />
                  <TextField
                    id="outlined-password-input"
                    label="Total Positions opened"
                    type="text"
                    className="inputEmail"
                    value={Positions}
                    // error={checker===true?"error":""}
                    onChange={(e)=>{
                      setPositions(e.target.value)
                    }}
                  />
            </div>
          
                  
                  <br/>
                  <Button className="btn-nav" variant="contained" onClick={(e)=>{
                    if(lotsize!=="" && Positions !==""){
                      setAverage(lotsize/Positions)
                      setMax((lotsize/Positions)*2)
                      setMin((lotsize/Positions)*0.25)
                      setCalculated(true)
                      // setLotsize("")
                      // setPositions("")
                    }
                      }}>Calculate</Button>
          </div>
            
                  <Footer/>
        </div>
    )
}
export default Lotsize;
