import * as React from 'react';
import AffliateIcon from "../img/affiliate.png";
function WhyUs(){
    return(
        <div className="WhyContainer">
            <div className='importantAboutUs'>
                <div><b>Low spread</b></div>
                <div><b>low commission</b></div>
                <div><b>7 Day payouts</b></div>
                <div><b>HFT allowed</b></div>
                <div><b>100% profit split</b></div>
            </div>
                <div className="PayoutSchedule"  data-aos="fade-up">

                    <div className="payoutWriting">
                    <h2>Why Us</h2>
                    <dl>
                    <dt><b>INSTANT FUNDING (HFT series)</b></dt>
                    <dd>Multiverse Funding provides instant funding "HFT series" accounts up to 200k funding, Start trading on a master account from day One of buying the account, you get a live account start trading and make money for payout<br/><br/>
                    </dd>
                    <dt><b>2 step evaluation</b></dt>
                    <dd>Multiverse Funding offers a low-fee, two-step challenge with a high success rate. You can retry your  phase 2 evaluation if needed.</dd>
                    </dl>
                    </div>

                    <div className="imgpayout">
                    <img src={AffliateIcon} alt="#"/>
                    </div>
                </div>
                
        </div>
    )
}
export default WhyUs;
