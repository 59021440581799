import React, { useState, useEffect } from 'react';
import Localbase from 'localbase'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Table, TableBody, TableCell, TableContainer, Chip, TableHead, TableRow, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
// Create an alert component using MUI's Alert with forwardRef to support transitions
const AlertSnack = React.forwardRef(function AlertSnack(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Withdraw = () => {
  let URL;
  if (process.env.NODE_ENV === 'production') {
    URL = "https://www.multiverse-funding.com";
  } else {
    URL = "http://localhost:8080";
    // URL = "https://www.multiverse-funding.com";
  }

  const [withdrawals, setwithdrawals] = useState([])
  const classes = useStyles();
  const [accountId, setaccountId] = React.useState([]);
  const [selectedId, setselectedId] = React.useState("");
  const [cryptoAddress, setcryptoAddress] = React.useState('');
  const [currentAddress, setCurrentAddress] = React.useState('');
  const [paymentMethod, setpaymentMethod] = React.useState('');
  const [amount, setamount] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const [value, setValue] = React.useState("");
  const [requesterror, setrequesterror] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleChangeAccountId = (event) => {
    setselectedId(event.target.value);
  };


  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);


  let db = new Localbase('Mfundingdashboard')
  useEffect(() => {
    db.collection('user').get().then(users => {
      if (users.length !== 0) {
        setEmail(users[0].email)
        setaccountId(users[0].accountIDs)
      }
    })
  }, []);

  useEffect(() => {
    if (selectedId !== "") {
      axios.post(`${URL}/api/get-withdrawal-amount`, { accountId: selectedId })
        .then(e => {
          setamount(e.data.amount)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [selectedId]);

  // /get-active-account
  useEffect(() => {
    if (email !== "") {
      getActiveAccounts(email).then(data => {

        if (data !== null) {
          console.log(data)
          setCurrentAddress(data.paymentAddress)
          setpaymentMethod(data.paymentMethod)
        }
      });
    }
  }, [email]);

  useEffect(() => {
    axios.post(`${URL}/api/get-withdrawal`, { email })
      .then(res => {
        console.log(res.data.withdrawals.Widthdrawals)
        setwithdrawals(res.data.withdrawals.Widthdrawals)
      })
      .catch(err => {
        console.log(err)
      })

  }, [email]);
  // Axios GET request to fetch active accounts
  const getActiveAccounts = async (email) => {
    try {
      const response = await axios.post(`${URL}/api/get-payment-details`, { email });
      return response.data;
    } catch (error) {
      console.error('Error fetching active accounts:', error.response ? error.response.data : error.message);
      return null;
    }
  };


  /*------------------------------Update Payement method--------------------------*/
  const updatePayment = async () => {
    if (value !== "" && cryptoAddress !== "" && email !== "") {
      try {
        const response = await axios.post(`${URL}/api/update-payment`, {
          email,
          paymentAddress: cryptoAddress,
          paymentMethod: value
        });
        if (response.data.success) {
          handleClick(response.data.message)
          setCurrentAddress(cryptoAddress)
          setpaymentMethod(value)
          setcryptoAddress("")
        }
      } catch (error) {
        console.error('Failed to update payment details:', error);
      }
    } else {
      alert("kindly fill the form")
    }
  };
  /*--------------------------------------end--------------------------------------*/


  const handleButtonClick = () => {
    if (email !== "" && amount > 0 && paymentMethod !== "" && currentAddress !== "" && selectedId !== "") {
      setSuccess(false);
      setLoading(true);
      axios.post(`${URL}/api/add-new-withdrawal`, { email, accountId: selectedId, paymentAddress: currentAddress, paymentMethod, amount: amount })
        .then(e => {
          if (e.data.success) {
            setrequesterror("")
            timer.current = setTimeout(() => {
              setSuccess(true);
              setLoading(false);
            }, 2000);
          }
        })
        .catch(err => {
          console.log(err)
          setrequesterror(err.response.data.message)
          setLoading(false);
        })
    }
  };
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(''); // State to hold the message

  const handleClick = (message) => {
    setMessage(message); // Set the message state
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return; // Do not close if clicked outside the Snackbar
    }
    setOpen(false);
  };
  const statusOrder = ["pending", "paid", "denied"]; // Define the order of status sorting

  function getStatusColor(status) {
    switch (status) {
      case "paid":
        return "success";
      case "pending":
        return "warning";
      case "denied":
        return "error";
      default:
        return "default";
    }
  }
  return (
    <div className='withdrawal-content'>
      <Card className='container-withdrawal'>

        <Card className='update-withdrawal'>
          <div>
            <Typography variant='h5'>Payment details</Typography>
            <Typography variant='p'>kindly put your crypto address correctly to avoid delay in payout or missing funds</Typography>
          </div>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Payment Option</FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="BTC Network" control={<Radio />} label="BTC Network" />
              <FormControlLabel value="USDT TRC20" control={<Radio />} label="USDT TRC20" />
              <FormControlLabel value="USDT ERC20" control={<Radio />} label="USDT ERC20" />
            </RadioGroup>
          </FormControl>
          <TextField value={cryptoAddress} onChange={(e) => { setcryptoAddress(e.target.value) }} id="outlined-basic" label="Wallet address" variant="outlined" />
          <Button style={{ width: "80px", marginTop: "5px", marginBottom: "5px" }} sx={{
            backgroundColor: 'rgba(15, 10, 70, 1)',
            '&:hover': {
              backgroundColor: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
            },
          }} onClick={updatePayment} variant="contained">Save</Button>
          <Alert severity="warning">You can't update payment options after submitting a withdrawal request. Contact support for any changes to payment details</Alert>
        </Card>

        <Card className='request-withdrawal'>
          <Typography sx={{ m: 1 }} variant='h6'>Request withdrawal</Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Account ID</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedId}
              label="Account ID"
              onChange={handleChangeAccountId}
            >
              {accountId.length !== 0 ? accountId.map(e => <MenuItem value={e}>{e}</MenuItem>) : ""}
            </Select>
          </FormControl>
          <TextField id="outlined-basic" InputProps={{ readOnly: true, }} value={currentAddress} label={paymentMethod} variant="outlined" />
          <TextField id="outlined-basic" InputProps={{ readOnly: true, }} value={`${amount.toFixed(1)}$`} label="Amount" variant="outlined" />
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="contained"
              disabled={success}
              onClick={handleButtonClick}
              sx={{
                backgroundColor: 'rgba(15, 10, 70, 1)',
                '&:hover': {
                  backgroundColor: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
                },
              }}
            >
              Submit
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                }}
              />
            )}
          </Box>
          {requesterror !== "" ? <Alert severity="error">{requesterror}</Alert> : ""}

        </Card>
      </Card>
      <Card className='history-withdrawal'>
        <Typography sx={{ m: 1 }} variant='h6'>Previous withdrawals</Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="previous withdrawals table">
            <TableHead>
              <TableRow>
                <TableCell>Account Number</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Request date</TableCell>
                <TableCell>Reviewed date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawals.map((withdrawal, index) => (
                <TableRow key={index}>
                  <TableCell>{withdrawal.accountId}</TableCell>
                  <TableCell>{withdrawal.amount}</TableCell>
                  <TableCell>{convertToHumanReadable(withdrawal.requestDate)}</TableCell>
                  <TableCell>{convertToHumanReadable(withdrawal.paymentDate)}</TableCell>
                  <TableCell>
                    <Chip
                      label={withdrawal.status}
                      color={getStatusColor(withdrawal.status)}
                      size="small"
                      sx={{ width: "70px" }}
                    />
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <div>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <AlertSnack onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {message}
          </AlertSnack>
        </Snackbar>
      </div>
    </div>
  );
};

export default Withdraw;

function convertToHumanReadable(isoDateString) {
  const date = new Date(isoDateString);
  const humanReadableDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short'
  });
  const humanReadableTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
  return `${humanReadableDate} at ${humanReadableTime}`;
}