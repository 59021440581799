import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogContent, DialogActions, TextField, DialogTitle, FormControlLabel, Checkbox } from '@mui/material';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { Snackbar, Alert } from '@mui/material';

function Upgrade() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [accounts, setAccounts] = useState([])
    const [operationAccount, setoperationAccount] = useState({})
    const [upgrade, setUpdate] = useState(false)
    let url;
    if (process.env.NODE_ENV == 'production') {
        url = "https://www.multiverse-funding.com";
    } else {
        url = "http://localhost:8080";
    }
    useEffect(() => {
        axios.get(`${url}/api/find-upgrade`)
            .then(res => {
                setAccounts(res.data.upgrades)
            })
            .catch(err => {
                console.log(err)
            })
    }, [upgrade]);
    const handleApprove = (account) => {
        axios.post(`${url}/api/upgrade-user-trading-account`, { id: account.accountDetails._id, email: account.accountDetails.accountEmail, planId: account.planId, upgradeId: account._id })
            .then(res => {
                console.log(res)
                setSnackbarMessage("upgraded successfully");
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                setUpdate(!upgrade)
            })
            .catch(err => {
                let message = err.response.data.message ? err.response.data.message : err.message
                setSnackbarMessage(message);
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                console.log(err)
            })
    };


    const status = {
        Active: "darkgreen",
        Passed: "darkblue",
        Violated: "darkred"

    }
    const [open, setOpen] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [terminateAccount, setTerminateAccount] = useState(false);
    const [refreshAccount, setRefreshAccount] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('')
    const handleClose = () => {
        setOpen(false);
    };
    const handleHistoryClose = () => {
        setOpenHistory(false);
    };
    const [history,setHistory]=useState([])
    const handleHistory = (account) => {
        setHistory(account.accountDetails.tradingHistory)
        setOpenHistory(true);
    };
    const handleReject = () => {
        axios.post(`${url}/api/deny-upgrade-user-trading-account`, { id: operationAccount.accountDetails._id, email: operationAccount.accountDetails.accountEmail, upgradeId: operationAccount._id, terminateAccount: terminateAccount, rejectionReason: rejectionReason, refreshAccount: refreshAccount })
            .then(res => {
                console.log(res)
                setSnackbarMessage("rejected successfully");
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                setUpdate(!upgrade)
                setOpen(false);
                setTerminateAccount(false)
                setRefreshAccount(false)
                setRejectionReason('')
            })
            .catch(err => {
                let message = err.response.data.message ? err.response.data.message : err.message
                setSnackbarMessage(message);
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                console.log(err)
                setOpen(false);
            })
    };
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Account ID</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Account Status</TableCell>
                        <TableCell>Initial Balance</TableCell>
                        <TableCell>Current Balance</TableCell>
                        <TableCell>Account Type</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts.map((account) => (
                        <TableRow key={account.accountDetails.accountId}>
                            <TableCell>{account.accountDetails.accountId}</TableCell>
                            <TableCell>{account.accountDetails.accountEmail}</TableCell>
                            <TableCell><Chip
                                sx={{
                                    backgroundColor: status[account.status],
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: status[account.status],
                                    },
                                }}
                                label={account.status} /></TableCell>
                            <TableCell><Chip
                                sx={{
                                    backgroundColor: status[account.accountDetails.accountStatus],
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: status[account.accountDetails.accountStatus],
                                    },
                                }}
                                label={account.accountDetails.accountStatus} /></TableCell>
                            <TableCell>{account.accountDetails.InitialBalance ? formatNumber(account.accountDetails.InitialBalance) : 0}</TableCell>
                            <TableCell>{account.accountDetails.CurrentBalance ? formatNumber(account.accountDetails.CurrentBalance) : 0}</TableCell>
                            <TableCell>{account.accountDetails.accountType}</TableCell>
                            <TableCell>
                                <Button color="primary" onClick={() => handleApprove(account)}>Approve</Button>

                                <Button color="secondary" onClick={() => {
                                    setOpen(true);
                                    setoperationAccount(account)
                                }}>Reject</Button>
                                <Button color="primary" onClick={() => handleHistory(account)}>view history</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">Reject Upgrade</DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={terminateAccount}
                                onChange={(e) => setTerminateAccount(e.target.checked)}
                                name="terminateAccount"
                                color="primary"
                            />
                        }
                        label="Terminate Account"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={refreshAccount}
                                onChange={(e) => setRefreshAccount(e.target.checked)}
                                name="refreshAccount"
                                color="primary"
                            />
                        }
                        label="Refresh Account"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="rejection-reason"
                        label="Rejection Reason"
                        type="text"
                        fullWidth
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleReject} color="primary">
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openHistory} onClose={handleHistoryClose} fullWidth={true} maxWidth="lg">
                <DialogTitle id="form-dialog-title">View Account History</DialogTitle>
                <DialogContent>
                <TableContainer component={Paper} sx={{ bgcolor: '#221E24' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="trade history table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#ffffffdd' }}>Position ID</TableCell>
                                <TableCell sx={{ color: '#ffffffdd' }}>Symbol</TableCell>
                                <TableCell sx={{ color: '#ffffffdd' }}>Type</TableCell>
                                <TableCell sx={{ color: '#ffffffdd' }}>Entry Price</TableCell>
                                <TableCell sx={{ color: '#ffffffdd' }}>Closing Price</TableCell>
                                <TableCell sx={{ color: '#ffffffdd' }}>Lot</TableCell>
                                <TableCell sx={{ color: '#ffffffdd' }}>Profit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.length !== 0 && history.map((trade) => (
                                <TableRow
                                    key={trade.positionId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: '#ffffffdd' }}
                                >
                                    <TableCell sx={{ color: '#ffffffdd' }}>{trade.positionId}</TableCell>
                                    <TableCell sx={{ color: '#ffffffdd' }}>{trade.symbol}</TableCell>
                                    <TableCell sx={{ color: '#ffffffdd' }}>{trade.action}</TableCell>
                                    <TableCell sx={{ color: '#ffffffdd' }}>{trade.entryPrice}</TableCell>
                                    <TableCell sx={{ color: '#ffffffdd' }}>{trade.closingPrice}</TableCell>
                                    <TableCell sx={{ color: '#ffffffdd' }}>{trade.lot}</TableCell>
                                    <TableCell sx={{ color: '#ffffffdd' }}>{trade.profit}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHistoryClose} color="primary">
                        OKAY
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </TableContainer>
    );
}
export default Upgrade;
function formatNumber(number) {
    return new Intl.NumberFormat('en-US').format(number);
}