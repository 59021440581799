import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, IconButton, Paper, TableContainer, TablePagination, Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
function Users() {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        // URL = "http://localhost:8080";
        URL = "https://www.multiverse-funding.com";

    }
    // Fetch users from the backend
    useEffect(() => {
        axios.get(`${URL}/api/find-users`)
            .then(response => {
                console.log(response.data);
                setUsers(response.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);

    // Delete user by id and update on server
    const deleteUser = (id) => {
        axios.delete(`${URL}/api/delete-user/${id}`)
            .then(() => {
                // Remove user from state if deletion successful
                setUsers(users.filter(user => user.id !== id));
            })
            .catch(error => {
                console.error('Error deleting user:', error);
            });
    };

    // Filter users based on search query
    const filteredUsers = users.filter(user =>
        user.firstName.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
    );
    // Change page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Change rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function generatePassword(length = 12) {
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+=-{}[]:;\"'<>,.?/";
        let retVal = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }
    const [open, setOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        country: ''
    });

    useEffect(() => {
        if (open) {
            const newPassword = generatePassword();  // Generate a new password each time the dialog opens
            setFormData(prev => ({ ...prev, password: newPassword }));
        }
    }, [open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({ firstName: '', lastName: '', email: '', password: '', country: '' }); // Reset form data on close
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCreate = async () => {
        try {
            console.log(formData)
            const response = await axios.post(`${URL}/api/create-user-dashboard`, { firstName: formData.firstName, lastName: formData.lastName, email: formData.email, password: formData.password, country: formData.country });
            setSnackbar({ open: true, message: 'User created successfully!', severity: 'success' });
            handleClose();  // Close the dialog on success
        } catch (error) {
            console.log(error)
            setSnackbar({ open: true, message: error.response.data.error, severity: 'error' });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };
    return (
        <div className='overview-container' style={{ padding: '20px' }}>
            <Button style={{ margin: "10px" }} onClick={handleClickOpen} sx={{
                backgroundColor: 'rgba(15, 10, 70, 1)',
                '&:hover': {
                    backgroundColor: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
                },
            }} variant='contained'>Add User</Button>

            <TextField
                label="Search users..."
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: '20px' }}
            />
            <Paper style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }}>
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ color: 'rgba(12, 11, 11, 1)' }}>First Name</TableCell>
                                <TableCell style={{ color: 'rgba(12, 11, 11, 1)' }}>Last Name</TableCell>
                                <TableCell style={{ color: 'rgba(12, 11, 11, 1)' }}>Email</TableCell>
                                <TableCell style={{ color: 'rgba(12, 11, 11, 1)' }}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell style={{ color: 'rgba(12, 11, 11, 1)' }}>{user.firstName}</TableCell>
                                    <TableCell style={{ color: 'rgba(12, 11, 11, 1)' }}>{user.lastName}</TableCell>
                                    <TableCell style={{ color: 'rgba(12, 11, 11, 1)' }}>{user.email}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => deleteUser(user.id)} color="secondary">
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ color: 'white' }}
                />
            </Paper>

            <div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add New User</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.firstName}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="dense"
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.lastName}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="dense"
                            id="country"
                            name="country"
                            label="country"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.country}
                            onChange={handleInputChange}
                        />

                        <TextField
                            margin="dense"
                            id="password"
                            name="password"
                            label="Generated Password"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.password}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleCreate}>Create</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    message={snackbar.message}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            </div>
        </div>
    );
}

export default Users;
