import btc from "../img/Bitcoin.svg.webp";
import eth from "../img/eth.png";
import usdt from "../img/usdt.png";
import creditcard from "../img/creditcard.png";
import mt4 from "../img/mt4.png";
import mt5 from "../img/mt5.png";
import { SocialIcon } from 'react-social-icons'
import { Link } from "react-router-dom";
function Footer() {
    //url for mailchimp email
  const url="https://multiverse-funding.us9.list-manage.com/subscribe/post?u=87633bc5be15cc03d0aa0bb19&amp;id=0449c4f8be&amp;f_id=001e1be1f0"
  return (
    <div className="footer">
              <div className="footerWidth">
              <div>
              <h2>Multiverse Funding</h2>
              <p>© 2024 All rights reserved.</p>
              <p>Multiverse Funding offers one of the best trading environments for Virtual Money trading</p>
              <SocialIcon style={{margin:"0px 10px"}} url="https://discord.gg/nJWjX9AYzT" />
              <SocialIcon style={{margin:"0px 10px"}} url="https://instagram.com/multiversefunding" />
              <SocialIcon style={{margin:"0px 10px"}} url="https://telegram.me/multiversefunding"/>
              <SocialIcon style={{margin:"0px 10px"}} url="https://uk.trustpilot.com/review/multiverse-funding.org"/>
              </div>
              <div className="legal">
                <h4>LEGAL</h4>
                <Link to="/terms-and-conditions">Terms</Link>
                <Link to="/terms-and-conditions">Privacy Policy</Link>
                <Link to="/terms-and-conditions">Refund Policy</Link>
              </div>
              <div className="payment">
                <h4>Accepted Payment</h4>
                <img src={btc} alt="#"/>
                <img src={eth} alt="#"/>
                <img src={usdt} alt="#"/>
                <img className="cardvisa" src={creditcard} alt="#"/>
              </div>
              <div className="partner">
                <h4>Trading platform</h4>
                <nav>
                <p>MultiTrader</p>
                </nav>
              </div>
{/* 
              <div className="platform">
                  <h4>Platform</h4>
                  <nav>
                    <img src={mt4} alt="#"/>
                  </nav>
              </div>
               */}


              
              </div>
             </div>);
}

export default Footer;
