import './affliate.css'
import * as React from 'react';
import Footer from '../footer/Footer';
import Nav from '../navbar/Nav';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
function Affliate() {
  const [scroll, setScroll] = React.useState(false)
  React.useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0)
    }
    setScroll(false)
  }, []);
  const url = "https://www.multiverse-funding.com"
  const [showPassword, setShowPassword] = React.useState(false);
  //hook for login or sign up 
  const [login, setLogin] = React.useState(false);
  //show pw/hide
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //login credetentials
  const [email, setEmail] = React.useState("")
  const [password, setpassword] = React.useState("")
  const [username, setUsername] = React.useState("")
  const [IsEmpty, setIsEmpty] = React.useState(false)
  const [otp, setOtp] = React.useState()

  //loading screen 
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  // const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  // React.useEffect(() => {
  //   return () => {
  //     clearTimeout(timer.current);
  //   };
  // }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
    }
  };

  const [open, setOpen] = React.useState(false);
  const [opensuccess, setOpensuccess] = React.useState(false);
  const [openOTP, setOpenOTP] = React.useState(false);
  const [openUSER, setOpenUSER] = React.useState(false);
  const [openErrorText, setOpenErrorText] = React.useState(false);
  const [openEmailError, setOpenEmailError] = React.useState(false);
  const [errorRendered, setOpenerrorRendered] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseEmailError = () => {
    setOpenEmailError(false);
  };
  const handleClickOpenUSER = () => {
    setOpenUSER(true);
  };
  const handleClickOpensuccess = () => {
    setOpensuccess(true);
  };
  const handleClickOpenOTP = () => {
    setOpenOTP(true);
  };
  const handleClickErrorText = () => {
    setOpenErrorText(true);
  };
  const handleClickEmailError = () => {
    setOpenEmailError(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosesuccess = () => {
    setOpensuccess(false);
  };
  const handleCloseOTP = () => {
    setOpenOTP(false);
  };
  const handleCloseUSER = () => {
    setOpenUSER(false);
  };
  const handleCloseErrorText = () => {
    setOpenErrorText(false);
  };
  const ErrorText = () => {
    return (
      <Dialog
        open={openErrorText}
        onClose={handleCloseErrorText}
      >
        <DialogContent>
          <DialogContentText>
            {errorRendered}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleCloseErrorText()
          }}>OKAY</Button>
        </DialogActions>
      </Dialog>
    )
  }
  const [dashboard__inside, setDashboard_inside] = React.useState(true)
  const [dashboard, setDashboard] = React.useState(false)
  const [username_in, setUsername_in] = React.useState("No user")
  const [commission, setCommission] = React.useState(0)
  const [refferals, setReferrals] = React.useState([])
  const [PayoutHistory, setPayoutHistory] = React.useState([])

  const [openZ, setOpenZ] = React.useState(false);
  const handleClickZ = () => {
    setOpenZ(true);
  };
  const handleCloseZ = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenZ(false);
  };

  //payout referral
  const [CryptoAddress, setCryptoAddress] = React.useState("")
  return (
    <div className='Affliate-container'>
      <Nav />
      {dashboard === false ? <div>
        <div className='contain-affliate' >
          <div class="card">
            <div class="content">
              {login === true ?
                <div class="back">
                  <div class="back-content">
                    <h1>Login as an affiliate</h1>
                    <div className='login-field-affliate'>
                      <AccountCircle sx={{ color: IsEmpty ? "red" : "white", mr: 1, my: 0.5, width: "10%" }} />
                      <input value={email} onChange={(e) => { setEmail(e.target.value.toLowerCase().trim()) }} className='affliate-email-login' placeholder="Email" variant="outlined" />
                    </div>

                    <div className='login-field-affliate'>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        sx={{ color: IsEmpty ? "red" : "white", mr: 1, my: 0.5, width: "10%" }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                      <input value={password} onChange={(e) => { setpassword(e.target.value) }} className='affliate-pw-login' type={showPassword ? 'text' : 'password'} placeholder="Password" variant="outlined" />
                      <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', m: 1, width: "19%" }}>
                        <Button
                          sx={buttonSx}
                          disabled={loading} className='login-btn-affliate' variant="contained"
                          onClick={() => {
                            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                            if (email.match(validRegex) || password) {
                              handleButtonClick()
                              setIsEmpty(false)
                              axios.post("https://www.multiverse-funding.com/api/login", { email, password })
                                .then((a) => {
                                  // console.log(a.data)
                                  if (a.data.err) {
                                    console.log("wrong")
                                  } else {
                                    setUsername_in(a.data.AffliateUser.username)
                                    setReferrals(a.data.AffliateUser.refferals)
                                    setPayoutHistory(a.data.AffliateUser.PayoutHistory)
                                    setDashboard(true)
                                  }
                                  setSuccess(true);
                                  setLoading(false);
                                })
                                .catch((error) => {
                                  setLoading(false);
                                  //alert("wrong login or they've been an error")
                                  console.log(error);
                                });
                            } else {
                              setIsEmpty(true)
                            }
                          }}
                        >Login</Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Box>
                    </div>

                    <Button onClick={() => {
                      setLogin(!login)
                      setEmail("")
                      setpassword("")
                    }} className='choice-btn-affliate' variant="contained">Become an affiliate</Button>
                  </div>
                </div> :
                <div class="back">
                  <div class="back-content">

                    <h1>Become an affiliate</h1>
                    <p style={{ width: "80%", textAlign: "justify" }}>Get 10% commission when someone uses your referral code to purchase a challenge account, and the purchaser also gets a 5% discount on the account price</p>
                    <div className='login-field-affliate'>
                      <AccountCircle sx={{ color: 'white', mr: 1, my: 0.5, width: "10%" }} />
                      <input value={email} onChange={(e) => { setEmail(e.target.value.trim().toLocaleLowerCase()) }} className='affliate-email-login' placeholder="Email" variant="outlined" />
                    </div>
                    <div className='login-field-affliate'>
                      <AccountCircle sx={{ color: 'white', mr: 1, my: 0.5, width: "10%" }} />
                      <input value={username} onChange={(e) => { setUsername(e.target.value.trim()) }} className='affliate-email-login' placeholder="Username" variant="outlined" />
                    </div>
                    <div className='login-field-affliate'>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        sx={{ color: 'white', mr: 1, my: 0.5, width: "10%" }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                      <input value={password} onChange={(e) => { setpassword(e.target.value.trim()) }} className='affliate-pw-login' type={showPassword ? 'text' : 'password'} placeholder="Password" variant="outlined" />
                      <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', m: 1, width: "19%" }}>
                        <Button
                          sx={buttonSx}
                          disabled={loading} className='login-btn-affliate' variant="contained"
                          onClick={() => {
                            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                            if (email.match(validRegex) === null) {
                              setOpenerrorRendered("email invalid")
                              handleClickErrorText()
                            } else if (password.length < 6) {
                              setOpenerrorRendered("password should have more than 6 characters")
                              handleClickErrorText()
                            } else if (username === "") {
                              setOpenerrorRendered("Enter Username")
                              handleClickErrorText()
                            } else {
                              handleButtonClick()
                              axios.post("https://www.multiverse-funding.com/api/send-otp", { email })
                                .then(e => {
                                  if (e.data.success === true) {
                                    handleClickOpen()
                                  }
                                })
                                .catch(e => {
                                  if (e.response.data.message === "User is already registered") {
                                    handleClickEmailError()
                                  }
                                  alert("something went wrong, cant generate OTP")
                                  setSuccess(true);
                                  setLoading(false);


                                })

                            }
                          }}
                        >Sign Up</Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Box>
                    </div>
                    <Button onClick={() => {
                      setLogin(!login)
                      setEmail("")
                      setpassword("")
                    }} className='choice-btn-affliate' variant="contained">Login as an affiliate</Button>


                  </div>
                </div>}
            </div>
          </div>


        </div>
        <Dialog
          open={open}
          onClose={handleClose}>
          <DialogTitle>OTP</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the 6 digits code sent to your email account({email})
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="email"
              label="OTP"
              type="number"
              fullWidth
              variant="standard"
              value={otp}
              onChange={(e) => { setOtp(e.target.value) }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleClose();
              setSuccess(true);
              setLoading(false);
            }}>Cancel</Button>
            <Button onClick={(event) => {
              event.preventDefault();
              axios.post("https://www.multiverse-funding.com/api/signup", { email, username, password, otp })
                .then(e => {
                  if (e.data.success === true) {
                    setEmail("")
                    setpassword("")
                    setUsername("")
                    setSuccess(true);
                    setLoading(false);
                    handleClickOpensuccess()
                  } else {
                    alert("something went wrong")
                    setSuccess(true);
                    setLoading(false);
                  }

                  handleClose();
                })
                .catch(e => {
                  if (e.response.data.message === "The OTP is not valid") {
                    handleClickOpenOTP()
                  } else if (e.response.data.message === "User already exists") {
                    handleClickOpenUSER()

                  }
                })
            }}>Confirm</Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={opensuccess}
          onClose={handleClosesuccess}
        >
          <DialogContent>
            <DialogContentText>
              Account Created, proceed to login
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setLogin(!login); handleClosesuccess() }}>Login</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openEmailError}
          onClose={handleCloseEmailError}
        >
          <DialogContent>
            <DialogContentText>
              Email already registered, Use another Email Account
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setEmail(""); setpassword(""); handleCloseEmailError(); setUsername("") }}>Okay</Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={openOTP}
          onClose={handleCloseOTP}
        >
          <DialogContent>
            <DialogContentText>
              The OTP is not valid, request a new one
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleClosesuccess()
              handleClose();
              handleCloseOTP()
              setEmail("")
              setpassword("")
              setUsername("")
              setSuccess(true);
              setLoading(false);
              setOtp()
            }}>OKAY</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openUSER}
          onClose={handleCloseUSER}
        >
          <DialogContent>
            <DialogContentText>
              Username already exist, try another one
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleClosesuccess()
              handleClose();
              handleCloseOTP()
              setpassword("")
              setUsername("")
              setSuccess(true);
              setLoading(false);
              setOtp()
              handleCloseUSER()
            }}>OKAY</Button>
          </DialogActions>
        </Dialog>
        <ErrorText />
      </div> :

        <div style={{ marginTop: "50px", backgroundColor: "#0E1120", height: "100vh" }}>
          <div className="affliate-dashboard-container">
            <div className="affliate-dashboard-left">
              <ul className="affiate-dashboard-menu">
                <li onClick={() => { setDashboard_inside(true); setCommission(0) }}>Dashboard</li>
                <li onClick={() => {
                  setDashboard(false)
                  setDashboard_inside(true)
                  setEmail("")
                  setpassword("")
                  setUsername("")
                  setCommission(0)
                }}>Logout</li>
              </ul>
            </div>
            {dashboard__inside === true ?
              <div className="affliate-dashboard-right">
                <h2 style={{ marginLeft: "25px" }}>Welcome back {username_in}</h2>
                <div className="affliate-dashboard-right-inputs">

                  <div style={{ display: "flex", justifyContent: "flex-start", padding: "0px 20px", flexDirection: "column", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start", padding: "0px 20px" }}>
                      <TextField value={username_in} disabled className='inputdiscountcode' type="text" label="referral code" variant="outlined" />
                      <Button variant="contained" onClick={() => {
                        handleClickZ()
                        if (navigator.clipboard) {
                          navigator.clipboard.writeText(username_in).then(
                            function () {
                              console.log("Text copied to clipboard successfully!");
                            },
                            function (err) {
                              console.error("Failed to copy text: ", err);
                            }
                          );
                        } else {
                          // Fallback code for unsupported browsers
                        }
                      }}>
                        <ContentCopyIcon />
                      </Button>
                    </div>

                    <p>You will receive a 10% commission when someone uses your referral code to purchase a challenge account, and the purchaser also gets a 5% discount on the account price</p>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="history-affliate-withdrawal">
                      <p>Your earnings</p>
                      <div>
                        <table className="history-table-withdrawal">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Account</th>
                              <th>Commission</th>
                            </tr>
                          </thead>
                          <tbody>

                            {refferals.map((item, key) => <tr key={key}>
                              {/* {setCommission(commission+item.commission)} */}
                              <td>{item.name}</td>
                              <td>{item.account}</td>
                              <td>${item.commission}</td>
                            </tr>)}
                          </tbody>
                        </table>

                        <Button className='choice-btn-withdraw' onClick={() => {
                          console.log(refferals)
                          let commission_ref = 0
                          for (let i in refferals) {
                            if (refferals[i].commission !== undefined) {
                              commission_ref += refferals[i].commission

                            }



                          }
                          setCommission(commission_ref)
                          setDashboard_inside(false)
                        }} variant="contained">Withdraw Commission</Button>
                        {/* <div className="currentbalance">
                                <p>Current Balance</p>
                                <p>${commission}</p>
                            </div> */}
                        {/* <p>Total Commission earned: ${commission}</p> */}
                      </div>
                    </div>
                    <div className="history-affliate-withdrawal">
                      <p>Previous Withdrawals</p>
                      <div>
                        <table className="history-table-withdrawal">
                          <thead>
                            <tr>
                              <th>date</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {PayoutHistory.map((item, key) => <tr key={key}>
                              <td>{item.date}</td>
                              <td>${item.amount}</td>
                            </tr>)}
                          </tbody>
                        </table>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              : <div>
                <h3>Payment details USDT (TRC20) only</h3>
                <h4>Commission: ${commission}</h4>
                <div style={{ display: "flex", justifyContent: "flex-start", padding: "0px 20px" }}>
                  <TextField value={CryptoAddress} onChange={(e) => { setCryptoAddress(e.target.value) }} className='inputdiscountcode' type="text" label="USDT TRC20 Address" variant="outlined" />
                  <Button variant="contained" onClick={() => {
                    axios.get("https://www.multiverse-funding.com/api/payoutpending")
                      .then(a => {
                        //  setUsers(a.data.users)
                        //  console.log(a.data.users)
                        if (a.data.users.length !== 0) {
                          for (let i in a.data.users) {
                            if (a.data.users[i].username === username_in) {
                              alert("you currently have a pending withdraw, wait for it for complete before requesting another")
                            } else {

                              if (commission !== 0 && CryptoAddress !== "") {

                                axios.post(`${url}/api/requestpayout`, { username: username_in, CryptoAddress: CryptoAddress, Commission: commission })
                                  .then(a => {
                                    // console.log(a)
                                    if (a.data.success === true) {

                                      axios.post(`${url}/api/payoutrefferal`, { username: username_in })
                                        .then(event => {
                                          if (event.data.success === true) {
                                            alert("payout successfully requested, wait for a feedback within 3 to 5 Business days")
                                            setCommission(0)
                                            setCryptoAddress("")
                                            setReferrals([])
                                          }

                                        })
                                        .catch(e => {
                                          console.log(e)

                                        })

                                    }
                                  })
                                  .catch(e => {
                                    console.log(e)
                                    alert("something went wrong")
                                  })
                              }

                            }
                          }
                        } else {
                          if (commission !== 0 && CryptoAddress !== "") {

                            axios.post(`${url}/api/requestpayout`, { username: username_in, CryptoAddress: CryptoAddress, Commission: commission })
                              .then(a => {
                                // console.log(a)
                                if (a.data.success === true) {

                                  axios.post(`${url}/api/payoutrefferal`, { username: username_in })
                                    .then(event => {
                                      if (event.data.success === true) {
                                        alert("payout successfully requested, wait for a feedback within 3 to 5 Business days")
                                        setCommission(0)
                                        setCryptoAddress("")
                                        setReferrals([])
                                      }

                                    })
                                    .catch(e => {
                                      console.log(e)

                                    })

                                }
                              })
                              .catch(e => {
                                console.log(e)
                                alert("something went wrong")
                              })
                          }
                        }
                      })
                      .catch(e => {
                        alert("something went wrong")
                      })

                  }}>Submit</Button>
                </div>
              </div>}
          </div>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={openZ} autoHideDuration={3000} onClose={handleCloseZ}>
              <Alert onClose={handleCloseZ} severity="success" sx={{ width: '100%' }}>
                copied successfully!
              </Alert>
            </Snackbar>
          </Stack>
        </div>}

      <Footer />
    </div>
  )
}
export default Affliate;
