import React, { useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsIcon from '@mui/icons-material/Settings';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Localbase from 'localbase'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import UpgradeIcon from '@mui/icons-material/Upgrade';
const drawerWidth = 240;

const Sidebar = ({ openSideBar, handleDrawerToggle, onData }) => {
    const sendDataToParent = (data) => {
        onData(data);
    };
    const [isMfundingAdmin, setMfundingAdmin] = React.useState(false)
    const [isCollab, setisCollab] = React.useState(false)
    let db = new Localbase('Mfundingdashboard')
    useEffect(() => {
        db.collection('user').get().then(users => {
            if (users.length !== 0) {
                if (users[0].email === "support@multiverse-funding.com") {
                    setMfundingAdmin(true)
                    setisCollab(true)
                } else if (users[0].email === "n.cavallieri19@gmail.com" || users[0].email === "ebolfazl.alipour@gmail.com") {
                    setisCollab(true)
                    setMfundingAdmin(false)
                } else {
                    setisCollab(false)
                    setMfundingAdmin(false)
                }
            } else {
                setMfundingAdmin(false)
                setisCollab(false)
            }
        })
    }, []);
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={openSideBar}
        >
            <IconButton onClick={handleDrawerToggle}>
                <MenuIcon />
            </IconButton>
            <List>
                {/* Dashboard */}
                <ListItem button onClick={() => { sendDataToParent("Overview") }}>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Overview" />
                </ListItem>
                {isMfundingAdmin === true ?
                    <ListItem button onClick={() => { sendDataToParent("New") }}>
                        <ListItemIcon>
                            <AddCircleOutlineOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="New Account" />
                    </ListItem> : ""}
                {/* KYC */}
                <ListItem button onClick={() => { sendDataToParent("KYC") }}>
                    <ListItemIcon>
                        <VerifiedUserIcon />
                    </ListItemIcon>
                    <ListItemText primary="KYC" />
                </ListItem>
                {isMfundingAdmin === true ?
                    <ListItem button onClick={() => { sendDataToParent("ReviewKYC") }}>
                        <ListItemIcon>
                            <VerifiedUserIcon />
                        </ListItemIcon>
                        <ListItemText primary="Review KYC" />
                    </ListItem>
                    : ""}
                {/* Withdrawal */}
                <ListItem button onClick={() => { sendDataToParent("Withdrawal") }}>
                    <ListItemIcon>
                        <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary="Withdrawal" />
                </ListItem>

                {/* Setting */}
                <ListItem button onClick={() => { sendDataToParent("Setting") }}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Setting" />
                </ListItem>
                {isMfundingAdmin === true ?
                    <ListItem button onClick={() => { sendDataToParent("Users") }}>
                        <ListItemIcon>
                            <ManageAccountsOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItem>
                    : ""}
                {isMfundingAdmin === true ?
                    <ListItem button onClick={() => { sendDataToParent("Upgrade") }}>
                        <ListItemIcon>
                            <UpgradeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Upgrade" />
                    </ListItem>
                    : ""}
                {isMfundingAdmin === true ?
                    <ListItem button onClick={() => { sendDataToParent("Review$") }}>
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Review Payouts" />
                    </ListItem>
                    : ""}
                {isCollab === true ?
                    <ListItem button onClick={() => { sendDataToParent("Position") }}>
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Add Position" />
                    </ListItem>
                    : ""}
                {isMfundingAdmin === true ?
                    <ListItem button onClick={() => { sendDataToParent("TradingAccount") }}>
                        <ListItemIcon>
                            <ManageAccountsOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Accounts" />
                    </ListItem>
                    : ""}
                {isMfundingAdmin === true ?
                    <ListItem button onClick={() => { sendDataToParent("Plans") }}>
                        <ListItemIcon>
                            <StorageOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Plans" />
                    </ListItem>
                    : ""}



                {isMfundingAdmin === true ?
                    <ListItem button onClick={() => { sendDataToParent("Certificate") }}>
                        <ListItemIcon>
                            <WorkspacePremiumIcon />
                        </ListItemIcon>
                        <ListItemText primary="Certificate" />
                    </ListItem>
                    : ""}
                <ListItem button onClick={() => {
                    db.collection('user').delete().then(e => {
                        window.location.reload();
                    }).catch(err => { alert("failed to logout") })

                }}>
                    <ListItemIcon>
                        <LogoutOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default Sidebar;