import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Paper } from '@mui/material';
import axios from 'axios';
const Plans = () => {
    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
    }

    const [planName, setPlanName] = useState('');
    const [stages, setStages] = useState([]);
    const [stageDetails, setStageDetails] = useState({
        stageName: '',
        accountSize: '',
        dailyDrawdown: '',
        overallDrawdown: '',
        profitTarget: '',
        profitSplit: '',
        minimumTradingDays: '',
        totalDays:''
    });

    const handleStageChange = (e) => {
        const { name, value } = e.target;
        setStageDetails(prev => ({ ...prev, [name]: value}));
    };

    const addStage = () => {
        setStages(prevStages => [...prevStages, stageDetails]);
        setStageDetails({
            stageName: '',
            accountSize: '',
            dailyDrawdown: '',
            overallDrawdown: '',
            profitTarget: '',
            profitSplit: '',
            totalDays:'',
            minimumTradingDays: ''
        });
    };

    const savePlan = () => {
        const plan = {
            name: planName,
            stages
        };
        axios.post(`${URL}/api/create-plan`, plan)
            .then(res => {
                if (res.data.success) {
                    setPlanName('');
                    setStages([]);
                    alert("plan added successfully")
                }

            })
            .catch(err => {
                console.log(err)
            })
        console.log('Saving Plan:', plan);
        // Here you might want to send the plan to a server or another part of your application.

    };
    return (
        <div className='overview-container'>
            <Box sx={{ my: 4, mx: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Create a New Plan
                </Typography>
                <TextField
                    label="Plan Name"
                    variant="outlined"
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                    fullWidth
                    sx={{ mb: 2, maxWidth: 500, width: '100%' }} // Width adjustments here
                />
                <Typography variant="h6">Add Stages</Typography>
                <div style={{display:"flex",flexDirection:"column",width:"90%",margin:"0 auto",justifyContent:"center",alignItems:"center"}}>
                    {Object.keys(stageDetails).map(key => (
                        <TextField
                            key={key}
                            name={key}
                            label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                            value={stageDetails[key]}
                            onChange={handleStageChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            sx={{ maxWidth: 500, width: '100%' }} // Width adjustments here
                        />
                    ))}
                    <Button onClick={addStage} variant="contained" sx={{ mt: 1 }}>Add Stage</Button>


                </div>
                <Button style={{marginTop:"10px"}} onClick={savePlan} variant="contained" color="primary">
                    Save Plan
                </Button>

                {stages.length > 0 && (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6">Stages Preview:</Typography>
                        {stages.map((stage, index) => (
                            <Paper key={index} elevation={3} sx={{ p: 2, mt: 1 }}>
                                {Object.entries(stage).map(([key, value]) => (
                                    <Typography key={key}>{`${key}: ${value}`}</Typography>
                                ))}
                            </Paper>
                        ))}
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default Plans;