import axios from 'axios'
import { useState } from 'react';
import 'react-social-icons/discord'
import 'pure-react-carousel/dist/react-carousel.es.css';
import {TextField, Button} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Nav from "../navbar/Nav";
import * as React from 'react';
import Footer from "../footer/Footer"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EmailIcon from '@mui/icons-material/Email';
import RestoreIcon from '@mui/icons-material/Restore';
import './support.css'
function Support(){
    const [open, setOpen] = React.useState(false);
    const[loading,setloading]=useState(false)
    const [checker,setchecker]=useState(false)
    const [email, setEmail]=useState("")
    const [name,setName]=useState("")
    const [comment,setComment]=useState("")
    const [realcomment,setReaComment]=useState("")
  const [openerror, setOpenerror] = React.useState(false);
  const handleClickerror = () => {
    setOpenerror(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleCloseerror = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenerror(false);
  };
  const handleClick = () => {
    setOpen(true);
  };
    return(
        <div className='Support-container'>
          <div className='main-support-container'>
            <div className='contactus-email'>
              <h2>Contact Us</h2>
              <div>
                <p><EmailIcon  sx={{ fontSize: 30 }}/></p>
                <a href="mailto:support@multiverse-funding.com" style={{color:"#fff"}}>support@multiverse-funding.com</a>
              </div>
              <div>
                <p><RestoreIcon sx={{ fontSize: 30 }}/></p>
                <a href='/support' style={{color:"#fff", textDecoration:"none"}}>Support available 24/5 (Live Chat 6AM-6PM GMT)</a>
              </div>
            </div>
                <div className="contactus">
                <h3>Get in touch with us</h3>
                <div className="textfilling">
                <TextField
                id="outlined-password-input"
                label="Name"
                type="text"
                autoComplete="current-name"
                className="inputEmail"
                error={checker===true?"error":""}
                value={name}
                onChange={(e)=>{
                    setName(e.target.value)
                }}
                />
                <br/>
                <TextField
                id="outlined-password-input"
                label="Email"
                type="email"
                autoComplete="current-email"
                className="inputEmail"
                value={email}
                error={checker===true?"error":""}
                onChange={(e)=>{
                    setEmail(e.target.value)
                }}
                />
                <br/>
                <TextField
                error={checker===true?"error":""}
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                className="inputEmail"
                value={realcomment}
                onChange={(e)=>{
                    setReaComment(e.target.value)
                    setComment(`<p>${e.target.value}<br/><a href="mailto:${email}">reply</a></p>`)
                }}
                /><br/>
                <div style={{display:"flex"}}>
                <Button className="btn-nav" variant="contained" onClick={()=>{
                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    if(name!=="" && email.match(validRegex) && comment!==""){
                        setloading(true)
                        setchecker(false)
                        axios.post("https://www.multiverse-funding.com/api/sendmessage",{name,comment})
                        .then((response) =>{
                        const sent=response.data.slice(0,3);
                        // console.log(sent)
                        // console.log(response.status)
                        if(response.status===200 && sent==="250"){
                            setName("")
                            setEmail("")
                            setComment("")
                            setReaComment("")
                            handleClick()
                            setloading(false)
                        }
                        })
                        .catch( (error) =>{
                        handleClickerror()
                        // console.log(error);
                        setloading(false)
                        });
                    }else{
                        setchecker(true)
                    }
                    }}>  Submit</Button>{loading===true?<CircularProgress style={{display:"inline",marginLeft:"30px"}} color="success"/>:""}
                </div>
                    
                
                </div>
                
            </div>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                      message sent, support team will get back to you soon
                    </Alert>
            </Snackbar>
            <Snackbar open={openerror} autoHideDuration={2000} onClose={handleCloseerror}>
                    <Alert onClose={handleCloseerror} severity="error" sx={{ width: '100%' }}>
                      something went wrong
                    </Alert>
            </Snackbar>
            <Nav/>
            <Footer/>
            </div>
        </div>
    )
}
export default Support;
